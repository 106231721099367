import React, { useState, useEffect, useContext } from 'react';
import MonevTable from '../MonevTable';
import { ANNOUNCEMENTS, SET_ANNOUNCEMENT } from '../../graphql/Announcements';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const AnnouncementPage = (props) => {
  const { query, history, token, mutate } = props;
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    const getAnnouncements = async () => {
      setGlobalLoading(true);
      const {
        data: { announcements },
      } = await query({
        query: ANNOUNCEMENTS,
        variables: {
          all: true,
        },
        fetchPolicy: 'no-cache',
      });

      setAnnouncements(announcements);
      setGlobalLoading(false);
    };

    getAnnouncements();
  }, [query, setGlobalLoading, token]);

  const handleUpdate = async (active, aid) => {
    await mutate({
      mutation: SET_ANNOUNCEMENT,
      variables: {
        aid,
        active,
      },
    });

    const old = announcements;
    const newData = old.map((x) => {
      if (x.aid === aid) {
        return {
          ...x,
          active,
        };
      }

      return x;
    });
    setAnnouncements([...newData]);
    setSnack({
      variant: 'success',
      message: 'Status Pengumuman berhasil diupdate',
      opened: true,
    });

    setGlobalLoading(false);
    history.replace('/announcement');
  };

  const header = [
    { id: 'id', label: 'ID', maxWidth: '5%' },
    { id: 'title', label: 'Judul', maxWidth: '15%' },
    { id: 'description', label: 'Keterangan', maxWidth: '30%' },
    { id: 'active', label: 'Status', maxWidth: '10%' },
    { id: 'created_at', label: 'Tanggal Rilis', maxWidth: '20%' },
    { id: 'action', label: 'Aksi', maxWidth: '5%' },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Pengumuman' }]} />
      <MonevTable
        history={history}
        header={header}
        data={announcements}
        title="Data Pengumuman"
        editUrl="announcement"
        editKey="aid"
        handleUpdate={handleUpdate}
      />
    </div>
  );
};

export default AnnouncementPage;
