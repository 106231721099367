import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'universal-cookie/cjs';
import MonevTable from '../MonevTable';
import { COMPLAINTS } from '../../graphql/Complaints';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const ComplaintPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const cookie = new Cookies();

  useEffect(() => {
    const getUsers = async () => {
      setGlobalLoading(true);
      const {
        data: { complaints },
      } = await query({
        query: COMPLAINTS,
        variables: {
          company_id: cookie.get('cid'),
        },
        fetchPolicy: 'no-cache',
      });

      setUsers(complaints);
      setGlobalLoading(false);
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setGlobalLoading]);

  const header = [
    { id: 'id', label: 'ID', maxWidth: 10 },
    { id: 'title', label: 'Judul Laporan', maxWidth: 300 },
    { id: 'complaint_type.name', label: 'Jenis Laporan', maxWidth: 200 },
    { id: 'location', label: 'Lokasi', maxWidth: 100 },
    { id: 'contact_person', label: 'Contact Person', maxWidth: 100 },
    { id: 'incident_date', label: 'Tanggal Kejadian', maxWidth: 100 },
    { id: 'created_at', label: 'Tanggal Dibuat', maxWidth: 100 },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Pengaduan' }]} />
      <MonevTable
        history={history}
        header={header}
        data={users}
        title="Data Pengaduan"
        editUrl="complaints"
        editKey="clid"
        noAdd
      />
    </div>
  );
};

export default ComplaintPage;
