import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Container,
} from '@mui/material';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { FIND_FLEET_BY_FID } from '../../graphql/Fleets';
import GlobalContext from '../../utils/GlobalContext';

const FleetDetail = (props) => {
  const { history, query, match, company } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [fleet, setFleet] = useState({});

  useEffect(() => {
    const getFleet = async () => {
      setGlobalLoading(true);
      const {
        data: { findFleetByFid },
      } = await query({
        query: FIND_FLEET_BY_FID,
        variables: {
          fid: match.params.fid,
        },
        fetchPolicy: 'no-cache',
      });

      setFleet(findFleetByFid);
    };

    getFleet();
    setGlobalLoading(false);
  }, [match.params.fid, query, setGlobalLoading]);

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Detail Armada' }]} />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="inspectionNumber"
                  name="inspectionNumber"
                  required
                  fullWidth
                  id="inspectionNumber"
                  label="Nomor Uji KIR"
                  placeholder="Contoh: TA 1234"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.inspection_number}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="licensePlate"
                  name="licensePlate"
                  required
                  fullWidth
                  id="licensePlate"
                  label="Plat Nomor"
                  placeholder="Contoh: AG 1234 AB"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.license_plate}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="vehicle"
                  name="vehicle"
                  required
                  fullWidth
                  id="vehicle"
                  label="Jenis Armada"
                  placeholder="Mobil Box"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.vehicle_purpose?.name}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="inspectionDate"
                  fullWidth
                  required
                  label="Tanggal Uji KIR"
                  type="text"
                  sx={{ width: 220 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: '100%' }}
                  value={fleet?.inspection_date}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="chassisNumber"
                  name="chassisNumber"
                  required
                  fullWidth
                  id="chassisNumber"
                  label="Nomor Rangka"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.chassis_number}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="engineNumber"
                  name="engineNumber"
                  required
                  fullWidth
                  id="engineNumber"
                  label="Nomor Mesin"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.engine_number}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="brand"
                  name="brand"
                  required
                  fullWidth
                  id="brand"
                  label="Merek"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.brand}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="type"
                  name="type"
                  fullWidth
                  id="type"
                  label="Tipe"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.type}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoComplete="routes"
                  name="routes"
                  multiline
                  maxRows={4}
                  rows={4}
                  fullWidth
                  id="routes"
                  label="Rute Trayek"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={fleet?.routes}
                  readOnly
                />
              </Grid>
            </Grid>
            &nbsp;
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              // onClick={() => history.replace('/fleet')}
              onClick={() => {
                if (company) {
                  history.replace(`/company/detail/${match.params.cid}`);
                } else {
                  history.replace('/fleet');
                }
              }}
            >
              Kembali
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FleetDetail;
