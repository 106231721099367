import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MonevTable from '../MonevTable';
import { BLOGS, DELETE_BLOG } from '../../graphql/Blog';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const NewsPage = (props) => {
  const { query, history, token, mutate } = props;
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [blog, setBlog] = useState([]);
  const [open, setOpen] = useState(false);
  const [bid, setBid] = useState(null);

  useEffect(() => {
    const getBlog = async () => {
      setGlobalLoading(true);
      const {
        data: { blogs },
      } = await query({
        query: BLOGS,
        variables: {
          all: true,
        },
        fetchPolicy: 'no-cache',
      });

      setBlog(blogs);
      setGlobalLoading(false);
    };

    getBlog();
  }, [query, setGlobalLoading, token]);

  const handleClickOpen = (bid) => {
    setBid(bid);
    setOpen(true);
  };

  const handleClose = () => {
    setBid(null);
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const old = blog;

      const {
        data: { deleteBlog },
      } = await mutate({
        mutation: DELETE_BLOG,
        variables: {
          bid,
        },
      });

      setOpen(false);
      const newBlogs = old.filter((x) => x.bid !== bid);
      setBlog([...newBlogs]);
      setBid(null);
      setSnack({
        variant: 'success',
        message: `${deleteBlog.title} berhasil dihapus`,
        opened: true,
      });
    } catch (error) {
      setSnack({
        variant: 'error',
        message: 'Carousel gagal ditambah',
        opened: true,
      });
    }
  };

  const header = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'title', label: 'Judul Berita' },
    { id: 'published', label: 'Status' },
    { id: 'featured', label: 'Berita Utama' },
    { id: 'total_view', label: 'Total Dilihat' },
    { id: 'created_at', label: 'Tanggal Rilis' },
    { id: 'edit_blog', label: 'Aksi', align: 'center' },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Berita' }]} />
      <MonevTable
        history={history}
        header={header}
        data={blog}
        title="Data Berita"
        editUrl="blog"
        editKey="bid"
        handleClickOpen={handleClickOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin untuk menghapus berita tersebut?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Berita yang telah terhapus tidak bisa ditampilkan lagi
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Tidak
          </Button>
          <Button variant="outlined" onClick={handleDelete} autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsPage;
