import { gql } from '@apollo/client';

export const SERVICE_TYPES = gql`
  query serviceTypes {
    serviceTypes {
      id
      stid
      name
      created_at
      updated_at
    }
  }
`;
