import React from 'react';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';
import MonevAdmin from './MonevAdmin';
import MonevSignIn from './MonevSignIn';
import MonevSignUp from './MonevSignUp';
import MonevSnack from './MonevSnack';
import MonevLoading from './MonevLoading';
import MonevVerification from './MonevVerification';
import LandingPage from './LandingPage/Blog';
import MonevForgotPassword from './MonevForgotPassword';
import MonevThankYou from './MonevThankYou';

const cookies = new Cookies();
const PrivateRoute = (prop) => (
  <div>
    <Route
      path="/"
      render={(props) =>
        cookies.get('token') ? (
          <MonevAdmin {...prop} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  </div>
);

const MyRouter = (greaterProps) => {
  return (
    <>
      {greaterProps.globalLoading && <MonevLoading />}
      <Router>
        <Switch>
          <Route
            exact
            path="/sign_in"
            render={(props) => (
              <MonevSignIn
                query={greaterProps.client.query}
                token={cookies.get('token')}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/sign_up"
            render={(props) => (
              <MonevSignUp
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/password_confirmation/:token"
            render={(props) => (
              <MonevVerification
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/home"
            render={(props) => (
              <LandingPage query={greaterProps.client.query} {...props} />
            )}
          />
          <Route
            exact
            path="/pengaduan"
            render={(props) => (
              <LandingPage
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/pengumuman/:aid"
            render={(props) => (
              <LandingPage
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/pengumuman"
            render={(props) => (
              <LandingPage
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/berita/:bid"
            render={(props) => (
              <LandingPage
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/berita"
            render={(props) => (
              <LandingPage
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/forgot_password"
            render={(props) => (
              <MonevForgotPassword
                query={greaterProps.client.query}
                mutate={greaterProps.client.mutate}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/complete_registration"
            render={(props) => <MonevThankYou {...props} />}
          />
          <PrivateRoute
            query={greaterProps.client.query}
            mutate={greaterProps.client.mutate}
          />
          <Route path="*">
            <>404</>
          </Route>
          <Route path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
        <MonevSnack {...greaterProps.snack} />
      </Router>
    </>
  );
};

export default MyRouter;
