import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Container,
} from '@mui/material';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { FIND_USER_BY_UID } from '../../graphql/User';
import GlobalContext from '../../utils/GlobalContext';

const DriverDetail = (props) => {
  const { history, query } = props;
  const [user, setUser] = useState({});
  const { setGlobalLoading } = useContext(GlobalContext);

  useEffect(() => {
    const getUser = async () => {
      setGlobalLoading(true);
      const {
        data: { findUserByUid },
      } = await query({
        query: FIND_USER_BY_UID,
        variables: {
          uid: props.match.params.uid,
        },
        fetchPolicy: 'no-cache',
      });
      console.log('findUserByUid', findUserByUid);
      setUser(findUserByUid);
      setGlobalLoading(false);
    };

    getUser();
  }, [props.match.params.uid, query, setGlobalLoading]);

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Detail Anggota' }]} />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  fullWidth
                  id="name"
                  label="Nama Anggota"
                  value={user.name || '-'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoComplete="handphone"
                  name="handphone"
                  fullWidth
                  id="handphone"
                  label="Nomor Handphone"
                  placeholder="Ex: 08123456789"
                  value={user.handphone || '-'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoComplete="role"
                  name="role"
                  fullWidth
                  id="role"
                  label="Peran"
                  placeholder="Admin"
                  value={user?.role?.name || '-'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  readOnly
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoComplete="driver_license"
                  name="driver_license"
                  fullWidth
                  id="driver_license"
                  label="Nomor SIM"
                  placeholder="12345678890"
                  value={user.driver_license || '-'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  readOnly
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => history.replace('/driver')}
            >
              Kembali
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default DriverDetail;
