import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Container,
} from '@mui/material';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import {
  CREATE_COMPLAINT_TYPE,
  FIND_COMPLAINT_TYPE_BY_CPID,
  UPDATE_COMPLAINT_TYPE,
} from '../../graphql/ComplaintTypes';
import GlobalContext from '../../utils/GlobalContext';

const ComplaintTypeForm = (props) => {
  const { history, query, mutate, editable, match } = props;
  const [name, setName] = useState(null);
  const [error, setError] = useState(false);
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);

  useEffect(() => {
    if (editable) {
      const getComplaintType = async () => {
        setGlobalLoading(true);
        const {
          data: { findComplaintTypeByCpid },
        } = await query({
          query: FIND_COMPLAINT_TYPE_BY_CPID,
          variables: { cpid: match.params.cpid },
          fetchPolicy: 'no-cache',
        });

        setName(findComplaintTypeByCpid.name);
        setGlobalLoading(false);
      };

      getComplaintType();
    }
  }, [editable, match.params.cpid, query, setGlobalLoading]);

  const handleSubmit = async () => {
    try {
      if (name) {
        setGlobalLoading(true);
        let result;
        let message;
        if (editable) {
          const {
            data: { updateComplaintType },
          } = await mutate({
            mutation: UPDATE_COMPLAINT_TYPE,
            variables: { cpid: match.params.cpid, data: { name: name.trim() } },
          });

          result = updateComplaintType;
          message = `Data jenis pengaduan ${result.name} berhasil diubah`;
        } else {
          const {
            data: { createComplaintType },
          } = await mutate({
            mutation: CREATE_COMPLAINT_TYPE,
            variables: { data: { name: name.trim() } },
          });

          result = createComplaintType;
          message = `Data jenis pengaduan ${result.name} berhasil ditambahkan`;
        }

        setSnack({
          variant: 'success',
          message: message,
          opened: true,
        });

        setGlobalLoading(false);
        history.replace('/complaint_type');
      } else {
        setError(true);
      }
    } catch (error) {
      setGlobalLoading(false);
    }
  };

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Detail Jenis Pengaduan' }]} />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  fullWidth
                  autoFocus
                  required
                  id="name"
                  label="Jenis Pengaduan"
                  onChange={(e) => {
                    setError(false);
                    setName(e.target.value);
                  }}
                  value={name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={error}
                  helperText={error && 'Harus diisi'}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => handleSubmit()}
            >
              Simpan
            </Button>{' '}
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => history.replace('/complaint_type')}
            >
              Kembali
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default ComplaintTypeForm;
