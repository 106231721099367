import { gql } from '@apollo/client';

export const FIND_FLEET_BY_FID = gql`
  query findFleetByFid($fid: String!) {
    findFleetByFid(fid: $fid) {
      id
      fid
      company_id
      vehicle_purpose_id
      inspection_number
      license_plate
      chassis_number
      engine_number
      brand
      type
      inspection_date
      end_of_inspection_date
      vehicle_purpose {
        id
        name
      }
      extra
      routes
      raw_of_inspection_date
    }
  }
`;

export const FIND_FLEET_BY_COMPANY_ID = gql`
  query findFleetByCompanyId(
    $company_id: ID
    $options: ListOptions
    $status: String
  ) {
    findFleetByCompanyId(
      company_id: $company_id
      options: $options
      status: $status
    ) {
      total
      fleets {
        id
        fid
        company_id
        vehicle_purpose_id
        inspection_number
        license_plate
        chassis_number
        engine_number
        brand
        type
        inspection_date
        end_of_inspection_date
        vehicle_purpose {
          id
          name
        }
        extra
        routes
      }
    }
  }
`;

export const CREATE_FLEET = gql`
  mutation createFleet($data: InFleet!) {
    createFleet(data: $data) {
      id
      fid
      license_plate
      brand
      vehicle_purpose {
        id
        name
      }
      company {
        id
        name
      }
      routes
    }
  }
`;

export const UPDATE_FLEET = gql`
  mutation updateFleet($fid: String!, $data: InFleet!) {
    updateFleet(fid: $fid, data: $data) {
      id
      fid
      license_plate
      brand
      vehicle_purpose {
        id
        name
      }
      company {
        id
        name
      }
      routes
    }
  }
`;

export const FIND_FLEET_BY_INSPECTION_NUMBER = gql`
  query findFleetByInspectionNumber($inspection_number: String!) {
    findFleetByInspectionNumber(inspection_number: $inspection_number) {
      id
      fid
      company_id
      vehicle_purpose_id
      inspection_number
      license_plate
      chassis_number
      engine_number
      brand
      type
      inspection_date
      end_of_inspection_date
      vehicle_purpose {
        name
      }
      extra
      routes
    }
  }
`;

export const CLAIM_FLEET = gql`
  mutation claimFleet($fid: String!) {
    claimFleet(fid: $fid) {
      id
      fid
      company_id
      routes
    }
  }
`;

export const IMPORT_FLEET = gql`
  mutation importFleet($data: [InFleet]!) {
    importFleet(data: $data) {
      id
      fid
      license_plate
      brand
      vehicle_purpose {
        id
        name
      }
      company {
        id
        name
      }
      routes
    }
  }
`;
