import React, { useContext, useState, useEffect } from 'react';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  CssBaseline,
  TextField,
  FormHelperText,
  Grid,
  Box,
  Container,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { CREATE_FLEET } from '../../graphql/Fleets';
import { VEHICLE_PURPOSES } from '../../graphql/VehiclePurpose';
import GlobalContext from '../../utils/GlobalContext';

const FleetForm = (props) => {
  const { history, mutate, query } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [vehiclePurposes, setVehiclePurposes] = useState([]);
  const currentDate = dayjs().format('YYYY-MM-DD');

  useEffect(() => {
    const getVehiclePurposes = async () => {
      setGlobalLoading(true);
      const {
        data: { vehiclePurposes },
      } = await query({
        query: VEHICLE_PURPOSES,
        fetchPolicy: 'no-cache',
      });

      setVehiclePurposes(vehiclePurposes);
    };

    getVehiclePurposes();
    setGlobalLoading(false);
  }, [query, setGlobalLoading]);

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Tambah Armada' }]} />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Formik
            initialValues={{
              vehiclePurpose: '',
              inspectionNumber: '',
              licensePlate: '',
              chassisNumber: '',
              engineNumber: '',
              brand: '',
              type: '',
              inspectionDate: currentDate,
              routes: '',
            }}
            validationSchema={Yup.object().shape({
              vehiclePurpose: Yup.string().required('Harus diisi'),
              inspectionNumber: Yup.string().required('Harus diisi'),
              inspectionDate: Yup.string().required('Harus diisi'),
              licensePlate: Yup.string().required('Harus diisi'),
              chassisNumber: Yup.string().required('Harus diisi'),
              engineNumber: Yup.string().required('Harus diisi'),
              brand: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async ({
              vehiclePurpose,
              inspectionNumber,
              inspectionDate,
              licensePlate,
              chassisNumber,
              engineNumber,
              brand,
              type,
              routes,
            }) => {
              try {
                setGlobalLoading(true);

                const data = {
                  vehicle_purpose_id: vehiclePurpose,
                  inspection_number: inspectionNumber.trim(),
                  license_plate: licensePlate.trim(),
                  chassis_number: chassisNumber.trim(),
                  engine_number: engineNumber.trim(),
                  brand: brand.trim(),
                  type: type.trim(),
                  inspection_date: inspectionDate,
                  routes: routes.trim(),
                };

                let result;
                let message;

                const {
                  data: { createFleet },
                } = await mutate({
                  mutation: CREATE_FLEET,
                  variables: {
                    data,
                  },
                });

                result = createFleet;
                message = `Data armada ${result.license_plate} berhasil ditambahkan`;

                setSnack({
                  variant: 'success',
                  message: message,
                  opened: true,
                });

                setGlobalLoading(false);
                history.replace('/fleet');
              } catch (error) {
                console.log('error', error);
                const message =
                  error.message || 'Terjadi kesalahan, silahkan coba lagi.';

                setSnack({
                  variant: 'error',
                  message,
                  opened: true,
                });
                setGlobalLoading(false);
              }
            }}
            render={(props) => {
              let { handleSubmit, handleChange, values, errors, touched } =
                props;
              return (
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="inspectionNumber"
                        name="inspectionNumber"
                        required
                        fullWidth
                        id="inspectionNumber"
                        label="Nomor Uji KIR"
                        placeholder="Contoh: TA 1234"
                        onChange={handleChange}
                        value={values.inspectionNumber}
                        error={
                          errors.inspectionNumber && touched.inspectionNumber
                        }
                        helperText={
                          errors.inspectionNumber &&
                          touched.inspectionNumber &&
                          errors.inspectionNumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="licensePlate"
                        name="licensePlate"
                        required
                        fullWidth
                        id="licensePlate"
                        label="Plat Nomor"
                        placeholder="Contoh: AG 1234 AB"
                        onChange={handleChange}
                        value={values.licensePlate}
                        error={errors.licensePlate && touched.licensePlate}
                        helperText={
                          errors.licensePlate &&
                          touched.licensePlate &&
                          errors.licensePlate
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl fullWidth>
                        <InputLabel id="vehiclePurpose">
                          Jenis Armada
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="vehiclePurpose"
                          label="Jenis Armada"
                          name="vehiclePurpose"
                          onChange={handleChange}
                          value={values.vehiclePurpose}
                          error={
                            errors.vehiclePurpose && touched.vehiclePurpose
                          }
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {vehiclePurposes.map((x) => (
                            <MenuItem value={x.id}>{x.name}</MenuItem>
                          ))}
                        </Select>
                        {errors.vehiclePurpose && touched.vehiclePurpose && (
                          <FormHelperText error>
                            {errors.vehiclePurpose}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        id="inspectionDate"
                        fullWidth
                        required
                        label="Tanggal Uji KIR"
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: '2021-11-18',
                        }}
                        style={{ width: '100%' }}
                        onChange={handleChange}
                        value={values.inspectionDate}
                        error={errors.inspectionDate && touched.inspectionDate}
                        helperText={
                          errors.inspectionDate &&
                          touched.inspectionDate &&
                          errors.inspectionDate
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="chassisNumber"
                        name="chassisNumber"
                        required
                        fullWidth
                        id="chassisNumber"
                        label="Nomor Rangka"
                        onChange={handleChange}
                        value={values.chassisNumber}
                        error={errors.chassisNumber && touched.chassisNumber}
                        helperText={
                          errors.chassisNumber &&
                          touched.chassisNumber &&
                          errors.chassisNumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="engineNumber"
                        name="engineNumber"
                        required
                        fullWidth
                        id="engineNumber"
                        label="Nomor Mesin"
                        onChange={handleChange}
                        value={values.engineNumber}
                        error={errors.engineNumber && touched.engineNumber}
                        helperText={
                          errors.engineNumber &&
                          touched.engineNumber &&
                          errors.engineNumber
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="brand"
                        name="brand"
                        required
                        fullWidth
                        id="brand"
                        label="Merek"
                        onChange={handleChange}
                        value={values.brand}
                        error={errors.brand && touched.brand}
                        helperText={
                          errors.brand && touched.brand && errors.brand
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="type"
                        name="type"
                        fullWidth
                        id="type"
                        label="Tipe"
                        onChange={handleChange}
                        value={values.type}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        autoComplete="routes"
                        name="routes"
                        multiline
                        rows={4}
                        fullWidth
                        id="routes"
                        label="Rute Trayek"
                        onChange={handleChange}
                        value={values.routes}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                    // disabled={button}
                  >
                    Simpan
                  </Button>{' '}
                  &nbsp;
                  <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => history.replace('/fleet')}
                  >
                    Kembali
                  </Button>
                </Box>
              );
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default FleetForm;
