import React, { useEffect, useContext, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TableHead from '@mui/material/TableHead';
import InfoIcon from '@mui/icons-material/Info';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SearchIcon from '@mui/icons-material/Search';
import Collapse from '@mui/material/Collapse';
import dayjs from 'dayjs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { FIND_COMPANY_BY_CID } from '../../graphql/Companies';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const Row = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.vehicle_purpose?.name}
        </TableCell>
        <TableCell align="right">{row.license_plate}</TableCell>
        <TableCell align="right">{row.brand}</TableCell>
        <TableCell align="right">{row.type}</TableCell>
        <TableCell align="right">{row.created_at}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Penggantian Suku Cadang
              </Typography>
              {row.spare_parts?.map((y, idx) => (
                <React.Fragment>
                  <Typography variant="subtitle2" sx={{ mt: 3 }}>
                    Tanggal Input {y.created_at}
                  </Typography>
                  <Table key={idx} size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>Suku Cadang</TableCell>
                        <TableCell>Merk</TableCell>
                        <TableCell>Kilometer</TableCell>
                        <TableCell>Tanggal</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key="oil">
                        <TableCell component="th" scope="row">
                          Oli Mesin
                        </TableCell>
                        <TableCell>
                          {!y.part?.oil.status ? y.part?.oil.brand : '-'}
                        </TableCell>
                        <TableCell>
                          {!y.part?.oil.status
                            ? Number(y.part?.oil.km).toLocaleString('id-ID')
                            : '-'}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: !y.part?.oil.status
                              ? '#fff176'
                              : null,
                          }}
                        >
                          {!y.part?.oil.status
                            ? dayjs(y.part?.oil.date).format('DD-MM-YYYY')
                            : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow key="oil_filter">
                        <TableCell component="th" scope="row">
                          Filter Oli
                        </TableCell>
                        <TableCell>
                          {!y.part?.oil_filter.status
                            ? y.part?.oil_filter.brand
                            : '-'}
                        </TableCell>
                        <TableCell>
                          {!y.part?.oil_filter.status
                            ? Number(y.part?.oil_filter.km).toLocaleString(
                                'id-ID'
                              )
                            : '-'}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: y.part?.oil_filter.date
                              ? '#fff176'
                              : null,
                          }}
                        >
                          {y.part?.oil_filter.date
                            ? dayjs(y.part?.oil_filter.date).format(
                                'DD-MM-YYYY'
                              )
                            : '-'}
                        </TableCell>
                      </TableRow>
                      {!y.part?.tire?.skipped &&
                        y.part?.tire?.tires?.map((x, i) => (
                          <TableRow key="tire">
                            <TableCell component="th" scope="row">
                              {x.name}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>
                              {x.km
                                ? Number(x.km).toLocaleString('id-ID')
                                : '-'}
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: x.date ? '#fff176' : null,
                              }}
                            >
                              {x.date
                                ? dayjs(x.date).format('DD-MM-YYYY')
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                      {!y.part?.extra.skipped &&
                        y.part?.extra?.extras.map((x, i) => (
                          <TableRow key="oil_filter">
                            <TableCell component="th" scope="row">
                              {x.name}
                            </TableCell>
                            <TableCell>-</TableCell>
                            <TableCell>-</TableCell>
                            <TableCell
                              style={{
                                backgroundColor: x.date ? '#fff176' : null,
                              }}
                            >
                              {x.date
                                ? dayjs(x.date).format('DD-MM-YYYY')
                                : '-'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </React.Fragment>
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CompanyDetail = (props) => {
  const { query, history } = props;
  const [company, setCompany] = useState({});
  const [value, setValue] = React.useState(0);
  const { setGlobalLoading } = useContext(GlobalContext);

  useEffect(() => {
    const getCompany = async () => {
      setGlobalLoading(true);
      const {
        data: { findCompanyByCid },
      } = await query({
        query: FIND_COMPANY_BY_CID,
        variables: {
          cid: props.match.params.cid,
        },
        fetchPolicy: 'no-cache',
      });

      setCompany(findCompanyByCid);
      setGlobalLoading(false);
    };

    getCompany();
  }, [props.match.params.cid, query, setGlobalLoading]);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const rows = [
    {
      title: 'Nama Perusahaan Otobus',
      row: 'name',
    },
    {
      title: 'Jenis Layanan',
      row: 'service_type',
    },
    {
      title: 'Alamat',
      row: 'address',
    },
    {
      title: 'Kecamatan',
      row: 'sub_district',
    },
    {
      title: 'Kelurahan / Desa',
      row: 'urban_village',
    },
    {
      title: 'NPWP',
      row: 'npwp',
    },
    {
      title: 'Email',
      row: 'email',
    },
    {
      title: 'Nomor Telepon',
      row: 'phone',
    },
    {
      title: 'Nomor Telepon Penanggung Jawab',
      row: 'responsible_phone',
    },
  ];

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Detail Perusahaan' }]} />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 200,
              }}
            >
              <Typography variant="h7" align="center" noWrap>
                Total Anggota
              </Typography>
              <Typography variant="h4" align="center" noWrap>
                {company?.total_user}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 200,
              }}
            >
              <Typography variant="h7" align="center" noWrap>
                Total Armada
              </Typography>
              <Typography variant="h4" align="center" noWrap>
                {company?.total_fleet}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 200,
              }}
            >
              <Typography variant="h7" align="center" noWrap>
                Total KIR Kedaluwarsa
              </Typography>
              <Typography variant="h4" align="center" noWrap>
                {company?.total_fleet_expired}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          height: 300,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab
            sx={{ width: '100%' }}
            label="Info Perusahaan"
            icon={<InfoIcon />}
            iconPosition="start"
            {...a11yProps(0)}
          />
          <Tab
            label="Anggota"
            icon={<PeopleAltIcon />}
            iconPosition="start"
            {...a11yProps(1)}
          />
          <Tab
            label="Armada"
            icon={<DirectionsCarIcon />}
            iconPosition="start"
            {...a11yProps(2)}
          />
          <Tab
            label="Suku Cadang"
            icon={<ConstructionIcon />}
            iconPosition="start"
            {...a11yProps(3)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              {/* <caption>A basic table example with a caption</caption> */}
              <TableBody>
                {rows.map((x, idx) => (
                  <TableRow key={idx}>
                    <TableCell sx={{ width: '20%' }}>{x.title}</TableCell>
                    <TableCell>
                      {x.row === 'service_type' && company?.service_type?.name}
                      {x.row === 'urban_village' &&
                        company?.urban_village?.name}
                      {x.row === 'sub_district' &&
                        company?.urban_village?.sub_district?.name}
                      {x.row !== 'service_type' &&
                        x.row !== 'urban_village' &&
                        x.row !== 'sub_district' &&
                        company[x.row]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>Nama</TableCell>
                  <TableCell>Peran</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Handphone</TableCell>
                  <TableCell>No. SIM</TableCell>
                  <TableCell>Tanggal Daftar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company?.users?.map((x) => (
                  <TableRow
                    key={x.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{x.name}</TableCell>
                    <TableCell>{x.role.name}</TableCell>
                    <TableCell>{x.email || '-'}</TableCell>
                    <TableCell>{x.handphone || '-'}</TableCell>
                    <TableCell>{x.driver_license || '-'}</TableCell>
                    <TableCell>{x.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell>No. KIR</TableCell>
                  <TableCell>Jenis</TableCell>
                  <TableCell>Plat Nomor</TableCell>
                  <TableCell>Merk</TableCell>
                  <TableCell>Tipe</TableCell>
                  <TableCell>Tanggal KIR</TableCell>
                  <TableCell>Berlaku Sampai</TableCell>
                  <TableCell>Aksi</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company?.fleets?.map((x) => (
                  <TableRow
                    key={x.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: x.expired ? '#f44336' : null,
                    }}
                  >
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.inspection_number}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.vehicle_purpose.name}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.license_plate}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.brand}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.type}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.inspection_date}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      {x.end_of_inspection_date}
                    </TableCell>
                    <TableCell sx={{ color: x.expired ? 'white' : null }}>
                      <Tooltip title="Detail">
                        <IconButton
                          href={`/company/${props.match.params.cid}/fleet/${x.fid}`}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TableContainer component={Paper}>
            <Table aria-label="caption table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Jenis</TableCell>
                  <TableCell align="right">Plat Nomor</TableCell>
                  <TableCell align="right">Merk</TableCell>
                  <TableCell align="right">Tipe</TableCell>
                  <TableCell align="right">Tanggal Dibuat</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {company?.fleets?.map((row) => (
                  <Row key={row.name} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
      </Box>
      <Button
        type="submit"
        variant="outlined"
        color="secondary"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => history.replace('/company')}
      >
        Kembali
      </Button>
    </>
  );
};

export default CompanyDetail;
