import { gql } from '@apollo/client';

export const VEHICLE_PURPOSES = gql`
  query vehiclePurposes {
    vehiclePurposes {
      id
      vpid
      name
      description
      created_at
      updated_at
    }
  }
`;
