import { gql } from '@apollo/client';

export const SPARE_PARTS = gql`
  query spareParts {
    spareParts {
      id
      sid
      fleet_id
      part
      created_at
      updated_at
      fleet {
        id
        fid
        company_id
        vehicle_purpose_id
        inspection_number
        license_plate
        chassis_number
        engine_number
        brand
        type
        inspection_date
        vehicle_purpose {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_SPARE_PART = gql`
  mutation createSparePart($data: InSparePart!) {
    createSparePart(data: $data) {
      id
      sid
      fleet_id
      part
      created_at
      updated_at
      fleet {
        id
        fid
        company_id
        vehicle_purpose_id
        inspection_number
        license_plate
        chassis_number
        engine_number
        brand
        type
        inspection_date
      }
    }
  }
`;
