/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useContext, Suspense } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { EditorState, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import TimeAgo from 'react-timeago';
import Header from './Header';
import Footer from './Footer';
import { BLOGS, UPDATE_VIEW, FIND_BLOG_BY_SLUG } from '../../graphql/Blog';
import { CAROUSELS } from '../../graphql/Carousels';
import {
  ANNOUNCEMENTS,
  FIND_ANNOUNCEMENT_BY_AID,
} from '../../graphql/Announcements';
import { Typography } from '@mui/material';
import GlobalContext from '../../utils/GlobalContext';

const sections = [
  { title: 'Home', url: '/home' },
  { title: 'Berita', url: '/berita' },
  { title: 'Pengumuman', url: '/pengumuman' },
  { title: 'Pengaduan', url: '/pengaduan' },
];
const CardFeatured = React.lazy(() => import('./CardFeatured'));
const Sidebar = React.lazy(() => import('./Sidebar'));
const Main = React.lazy(() => import('./Main'));
const Carousel = React.lazy(() => import('./Carousel'));
const ComplaintPage = React.lazy(() => import('./ComplaintPage'));
const theme = createTheme();

export default function Blog(props) {
  const { query, match, history } = props;
  const [blogs, setBlogs] = useState([]);
  const [blog, setBlog] = useState({});
  const [featuredPost, setFeaturedPost] = useState([]);
  const [carousels, setCarousels] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [announcement, setAnnouncement] = useState({});
  const { setGlobalLoading } = useContext(GlobalContext);

  useEffect(() => {
    const getBlogs = async () => {
      setGlobalLoading(true);
      const {
        data: { blogs },
      } = await query({
        query: BLOGS,
        fetchPolicy: 'no-cache',
      });

      const formatData = blogs.map((x) => {
        const obj = { ...x };

        const contentState = convertFromRaw(x.truncate_value);
        const editorState = EditorState.createWithContent(contentState);

        const contentValue = convertFromRaw(x.value);
        const valueState = EditorState.createWithContent(contentValue);

        obj.title = x.title;
        obj.date = x.published_at;
        obj.description = editorState;
        obj.value = valueState;
        obj.raw = x.value;
        obj.truncate_raw = x.truncate_value;
        setGlobalLoading(false);
        return obj;
      });

      setBlogs(formatData.filter((y) => !y.featured));
      setFeaturedPost(formatData.filter((y) => y.featured));
    };
    if (
      match?.path === '/home' ||
      match?.path === '/berita' ||
      match?.path === '/berita/:bid'
    ) {
      getBlogs();
    }
  }, [match?.path, query, setGlobalLoading]);

  useEffect(() => {
    const getCarousels = async () => {
      const {
        data: { carousels },
      } = await query({
        query: CAROUSELS,
        fetchPolicy: 'no-cache',
      });

      setCarousels(carousels);
    };

    if (match?.path === '/home') {
      getCarousels();
    }
  }, [match?.path, query]);

  useEffect(() => {
    const getAnnouncements = async () => {
      const limit = match?.path === '/home' ? true : false;
      const {
        data: { announcements },
      } = await query({
        query: ANNOUNCEMENTS,
        variables: {
          limit,
        },
        fetchPolicy: 'no-cache',
      });

      setAnnouncements(announcements);
    };

    if (
      match?.path === '/home' ||
      match?.path === '/pengumuman' ||
      match?.path === '/pengumuman/:aid'
    ) {
      getAnnouncements();
    }
  }, [match?.path, query]);

  useEffect(() => {
    if (match?.path === '/pengumuman/:aid') {
      const getAnnouncement = async () => {
        const {
          data: { findAnnouncementByAid },
        } = await query({
          query: FIND_ANNOUNCEMENT_BY_AID,
          variables: {
            aid: match?.params?.aid,
          },
          fetchPolicy: 'no-cache',
        });

        setAnnouncement(findAnnouncementByAid);
      };

      getAnnouncement();
    }
  }, [match?.params?.aid, match?.path, query]);

  useEffect(() => {
    if (match?.path === '/berita/:bid') {
      const updateView = async () => {
        await query({
          query: UPDATE_VIEW,
          variables: {
            bid: match?.params?.bid,
          },
          fetchPolicy: 'no-cache',
        });
      };

      const getBlog = async () => {
        const {
          data: { findBlogBySlug },
        } = await query({
          query: FIND_BLOG_BY_SLUG,
          variables: {
            slug: match?.params?.bid,
          },
          fetchPolicy: 'no-cache',
        });

        const contentState = convertFromRaw(findBlogBySlug.truncate_value);
        const editorState = EditorState.createWithContent(contentState);

        const contentValue = convertFromRaw(findBlogBySlug.value);
        const valueState = EditorState.createWithContent(contentValue);

        setBlog({
          ...findBlogBySlug,
          description: editorState,
          value: valueState,
          raw: findBlogBySlug.value,
        });
      };

      updateView();
      getBlog();
    }
  }, [match?.params.aid, match?.params?.bid, match?.path, query]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header title="SILekslamet" sections={sections} history={history} />
      <Container maxWidth="lg">
        <main style={{ minHeight: '600px', marginBottom: 50, marginTop: 85 }}>
          {match?.path === '/home' && (
            <>
              {/* SLIDER */}
              <Suspense fallback={<div></div>}>
                <Carousel post={carousels} />
              </Suspense>
              {featuredPost && featuredPost.length ? (
                <Grid container spacing={4}>
                  {featuredPost.slice(0, 2).map((post, i) => (
                    <Suspense fallback={<div></div>}>
                      <CardFeatured key={i} idx={i} post={post} />
                    </Suspense>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  <Skeleton variant="rectangular" />
                </Grid>
              )}
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={12} lg={12} textAlign={'center'}>
                  <img
                    src="/e09bd74a-ba82-4791-b0cd-124cd645ad9a.png"
                    alt="registration"
                    style={{
                      width: '90%',
                      cursor: 'pointer',
                    }}
                    onClick={() => window.open('/sign_up', '_blank')}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Suspense fallback={<div></div>}>
                  <Main title="Berita Umum" posts={blogs.slice(0, 5)} />
                  <Sidebar
                    title={'Pengumuman'}
                    announcements={announcements}
                    noPaginate
                  />
                </Suspense>
              </Grid>
            </>
          )}
          {match?.path === '/pengaduan' && (
            // PENGADUAN PAGE /pengaduan
            <Suspense fallback={<div></div>}>
              <ComplaintPage
                query={props.query}
                mutate={props.mutate}
                {...props}
              />
            </Suspense>
          )}
          {match?.path === '/pengumuman/:aid' && (
            // PENGUMUMAN PAGE /pengumuman/:aid
            <Suspense fallback={<div></div>}>
              <Grid container spacing={5} sx={{ mt: 0 }}>
                <Grid item xs={12} md={8}>
                  <div>
                    <Typography variant="h6">{announcement?.title}</Typography>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Typography variant="subtitle1">
                      {announcement?.description}
                    </Typography>
                  </div>
                  <div style={{ height: '100%', marginTop: 20 }}>
                    <iframe
                      src={announcement?.document}
                      frameborder={0}
                      style={{
                        height: '95%',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    ></iframe>
                  </div>
                </Grid>
                <Sidebar title={'Pengumuman'} announcements={announcements} />
              </Grid>
            </Suspense>
          )}
          {match?.path === '/pengumuman' && (
            // PENGUMUMAN PAGE /pengumuman
            <Suspense fallback={<div></div>}>
              <Grid container spacing={5} sx={{ mt: 0 }}>
                <Grid item xs={12} md={8}>
                  <div>
                    <Typography variant="h6">
                      {announcements[0]?.title}
                    </Typography>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Typography variant="subtitle1">
                      {announcements[0]?.description}
                    </Typography>
                  </div>
                  <div
                    style={{ height: '100%', marginTop: 20, marginBottom: 10 }}
                  >
                    <iframe
                      src={announcements[0]?.document}
                      frameborder={0}
                      style={{
                        height: '95%',
                        width: '100%',
                        overflow: 'hidden',
                      }}
                    ></iframe>
                  </div>
                </Grid>
                <Sidebar title={'Pengumuman'} announcements={announcements} />
              </Grid>
            </Suspense>
          )}
          {match?.path === '/berita/:bid' && (
            // BERITA PAGE /berita/:bid
            <Suspense fallback={<div></div>}>
              <Grid container spacing={5} sx={{ mt: 0 }}>
                <Grid item xs={12} md={8}>
                  <div>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                      {blog?.title}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      <TimeAgo date={blog?.publised_at} /> by {blog?.user?.name}
                    </Typography>
                    <Divider flexItem sx={{ marginTop: 1 }} />
                  </div>
                  <div>
                    <img
                      style={{
                        width: '100%',
                      }}
                      src={blog?.image}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Typography variant="subtitle1">
                      {blog?.value && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: draftToHtml(blog?.raw),
                          }}
                        />
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Sidebar title={'Berita'} posts={[...blogs, ...featuredPost]} />
              </Grid>
            </Suspense>
          )}
          {match?.path === '/berita' && (
            // BERITA PAGE /berita
            <Suspense fallback={<div></div>}>
              <Grid container spacing={5} sx={{ mt: 0 }}>
                <Grid item xs={12} md={8}>
                  <div>
                    <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                      {blogs[0]?.title}
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      <TimeAgo date={blogs[0]?.publised_at} /> by{' '}
                      {blogs[0]?.user?.name}
                    </Typography>
                    <Divider flexItem sx={{ marginTop: 1 }} />
                  </div>
                  <div>
                    <img
                      style={{
                        width: '100%',
                      }}
                      src={blogs[0]?.image}
                    />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Typography variant="subtitle1">
                      {blogs[0]?.value && (
                        // <Editor editorState={blogs[0]?.value} readOnly={true} />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: draftToHtml(blogs[0]?.raw),
                          }}
                        />
                      )}
                    </Typography>
                  </div>
                </Grid>
                <Sidebar title={'Berita'} posts={[...blogs, ...featuredPost]} />
              </Grid>
            </Suspense>
          )}
        </main>
      </Container>
      <Footer
        title="Dinas Perhubungan Kabupaten Tulungagung"
        description="Di Kelola Oleh Sekretariat Dinas Perhubungan Kabupaten Tulungagung"
      />
    </ThemeProvider>
  );
}
