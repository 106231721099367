import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDropzone } from 'react-dropzone';

export default function AlertDialog(props) {
  const { handleSave } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Import Data Armada
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h4>Unggah File</h4>
            <section className="container">
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '20px',
                  borderWidth: '2px',
                  borderRadius: '2px',
                  borderStyle: 'dashed',
                  backgroundColor: '#fafafa',
                  color: '#bdbdbd',
                  outline: 'none',
                  transition: 'border .24s ease-in-out',
                }}
                {...getRootProps({
                  className: 'dropzone',
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here</p>
              </div>
              <aside>
                <ul>{files}</ul>
              </aside>
            </section>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            variant="contained"
            onClick={() => handleSave(acceptedFiles)}
            disabled={!acceptedFiles.length}
            autoFocus
          >
            Unggah
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
