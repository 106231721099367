import React, { useContext, useEffect, useState } from 'react';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  CssBaseline,
  TextField,
  FormHelperText,
  Grid,
  Box,
  Container,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import {
  CREATE_MEMBER,
  UPDATE_MEMBER,
  FIND_USER_BY_UID,
} from '../../graphql/User';
import GlobalContext from '../../utils/GlobalContext';

const DriverForm = (props) => {
  const { history, mutate, match, editable, query } = props;
  const [user, setUser] = useState({
    name: '',
    role: '',
    handphone: '',
    driver_license: '',
  });
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);

  useEffect(() => {
    if (editable) {
      const getUser = async () => {
        setGlobalLoading(true);
        const {
          data: { findUserByUid },
        } = await query({
          query: FIND_USER_BY_UID,
          variables: {
            uid: props.match.params.uid,
          },
          fetchPolicy: 'no-cache',
        });

        setUser({
          name: findUserByUid.name,
          role: findUserByUid.role_id,
          handphone: findUserByUid.handphone,
          driver_license: findUserByUid.driver_license,
        });
        setGlobalLoading(false);
      };

      getUser();
    }
  }, [editable, props.match.params.uid, query, setGlobalLoading]);

  const roles = [
    {
      id: 3,
      name: 'Sopir',
    },
    {
      id: 4,
      name: 'Kernet',
    },
  ];
  return (
    <>
      <MonevBreadcrumbs
        item={[{ title: editable ? 'Ubah Anggota' : 'Tambah Anggota' }]}
      />
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Formik
            initialValues={{
              name: user?.name || '',
              role: user?.role || '',
              handphone: user?.handphone || '',
              driver_license: user?.driver_license || '',
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Harus diisi'),
              role: Yup.string().required('Harus diisi'),
              handphone: Yup.string().required('Harus diisi'),
              driver_license: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async ({ name, role, handphone, driver_license }) => {
              try {
                setGlobalLoading(true);

                const data = {
                  name: name.trim(),
                  role_id: role,
                  handphone: handphone.trim(),
                  driver_license: driver_license.trim(),
                };
                let result;
                let message;

                if (editable) {
                  const {
                    data: { updateMember },
                  } = await mutate({
                    mutation: UPDATE_MEMBER,
                    variables: {
                      uid: match.params.uid,
                      data,
                    },
                  });

                  result = updateMember;
                  message = `Data sopir ${result.name} berhasil diubah`;
                } else {
                  const {
                    data: { createMember },
                  } = await mutate({
                    mutation: CREATE_MEMBER,
                    variables: {
                      data,
                    },
                  });

                  result = createMember;
                  message = `Data sopir ${result.name} berhasil ditambahkan`;
                }

                setSnack({
                  variant: 'success',
                  message: message,
                  opened: true,
                });

                setGlobalLoading(false);
                history.replace('/driver');
              } catch (error) {
                console.log('error', error);
                setSnack({
                  variant: 'error',
                  message: 'Registrasi gagal, silahkan coba lagi.',
                  opened: true,
                });
                setGlobalLoading(false);
              }
            }}
            render={(props) => {
              let { handleSubmit, handleChange, values, errors, touched } =
                props;
              return (
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        autoComplete="name"
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="Nama Anggota"
                        autoFocus
                        onChange={handleChange}
                        value={values.name}
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        autoComplete="handphone"
                        name="handphone"
                        required
                        fullWidth
                        id="handphone"
                        label="Nomor Handphone"
                        placeholder="Ex: 08123456789"
                        onChange={handleChange}
                        value={values.handphone}
                        error={errors.handphone && touched.handphone}
                        helperText={
                          errors.handphone &&
                          touched.handphone &&
                          errors.handphone
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <FormControl fullWidth>
                        <InputLabel id="role">Peran</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="role"
                          label="Peran"
                          name="role"
                          onChange={handleChange}
                          value={values.role}
                          error={errors.role && touched.role}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {roles.map((x) => (
                            <MenuItem value={x.id}>{x.name}</MenuItem>
                          ))}
                        </Select>
                        {errors.role && touched.role && (
                          <FormHelperText error>{errors.role}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        autoComplete="driver_license"
                        name="driver_license"
                        required
                        fullWidth
                        id="driver_license"
                        label="Nomor SIM"
                        placeholder="12345678890"
                        onChange={handleChange}
                        value={values.driver_license}
                        error={errors.driver_license && touched.driver_license}
                        helperText={
                          errors.driver_license &&
                          touched.driver_license &&
                          errors.driver_license
                        }
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                    // disabled={button}
                  >
                    Simpan
                  </Button>{' '}
                  &nbsp;
                  <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => history.replace('/driver')}
                  >
                    Kembali
                  </Button>
                </Box>
              );
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default DriverForm;
