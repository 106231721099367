import React from 'react';
import Typography from '@mui/material/Typography';

const MonevCopyright = (props) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '} Dinas Perhubungan <br />
      Kabupaten Tulungagung {''}
      {new Date().getFullYear()}
    </Typography>
  );
};

export default MonevCopyright;
