import React, { useState, useEffect, useContext } from 'react';
import MonevTable from '../MonevTable';
import { COMPANIES } from '../../graphql/Companies';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const DriverPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      setGlobalLoading(true);
      const {
        data: { companies },
      } = await query({
        query: COMPANIES,
        fetchPolicy: 'no-cache',
      });

      setCompanies(companies);
      setGlobalLoading(false);
    };

    getUsers();
  }, [query, setGlobalLoading]);

  const header = [
    { id: 'id', label: 'ID', minWidth: 10 },
    { id: 'name', label: 'Nama Perusahaan', minWidth: 170 },
    { id: 'address', label: 'Alamat', minWidth: 100 },
    { id: 'phone', label: 'Nomor Telepon', minWidth: 100 },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Perusahaan' }]} />
      <MonevTable
        history={history}
        header={header}
        data={companies}
        title="Data Perusahaan"
        editable={true}
        editUrl="company"
        editKey="cid"
      />
    </div>
  );
};

export default DriverPage;
