/* eslint-disable no-throw-literal */
import React, { useState, useEffect, useContext } from 'react';
import MonevTable from '../MonevTable';
import { FIND_FLEET_BY_COMPANY_ID, IMPORT_FLEET } from '../../graphql/Fleets';
import { VEHICLE_PURPOSES } from '../../graphql/VehiclePurpose';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import Excel from 'exceljs/dist/es5/exceljs.browser';
import { saveAs } from 'file-saver';
import FleetImportPage from './FleetImportPage';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const FleetPage = (props) => {
  const { query, history, token, mutate, role, status } = props;
  const statusChip = ['all', 'claimed', 'unclaimed', 'expired', 'valid'];
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [fleets, setFleets] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [offset, setOffset] = useState(1);
  const [chip, setChip] = useState(
    statusChip.includes(status) ? status.toUpperCase() : 'ALL'
  );

  useEffect(() => {
    const getFleets = async () => {
      setGlobalLoading(true);
      const {
        data: { findFleetByCompanyId },
      } = await query({
        query: FIND_FLEET_BY_COMPANY_ID,
        variables: {
          options: {
            limit: 100,
            offset: 0,
          },
          status: chip,
        },
        fetchPolicy: 'no-cache',
      });

      setTotalData(findFleetByCompanyId.total);
      setFleets(findFleetByCompanyId.fleets);
      setGlobalLoading(false);
    };

    getFleets();
  }, [chip, query, setGlobalLoading, token]);

  const fetchVehiclePurposes = async () => {
    const {
      data: { vehiclePurposes },
    } = await query({
      query: VEHICLE_PURPOSES,
      variables: {
        options: {
          limit: 100,
          offset: 0,
        },
      },
      fetchPolicy: 'no-cache',
    });

    return vehiclePurposes;
  };

  const refetch = async (offset) => {
    const old = fleets;
    setGlobalLoading(true);

    const {
      data: { findFleetByCompanyId },
    } = await query({
      query: FIND_FLEET_BY_COMPANY_ID,
      variables: {
        options: {
          limit: 100,
          offset,
        },
        status: chip,
      },
      fetchPolicy: 'no-cache',
    });

    const newFleets = [...old, ...findFleetByCompanyId.fleets];
    setFleets([...newFleets]);
    setGlobalLoading(false);
  };

  const header = [
    { id: 'inspection_number', label: 'No. KIR', minWidth: 50 },
    { id: 'vehicle_purpose.name', label: 'Jenis', minWidth: 100 },
    { id: 'license_plate', label: 'Plat Nomor', minWidth: 100 },
    { id: 'brand', label: 'Merk', minWidth: 100 },
    { id: 'type', label: 'Tipe', minWidth: 100 },
    { id: 'inspection_date', label: 'Tanggal KIR', minWidth: 100 },
    { id: 'end_of_inspection_date', label: 'Berlaku Sampai', minWidth: 100 },
  ];

  const handleSave = async (acceptedFiles) => {
    try {
      let reader = new FileReader();
      reader.readAsArrayBuffer(acceptedFiles[0]);
      reader.onload = async () => {
        try {
          let buffer = reader.result;
          let workbook = await new Excel.Workbook().xlsx.load(buffer);
          let worksheet = workbook.getWorksheet('Armada');

          if (worksheet) {
            let newData = [];
            worksheet.eachRow((row, rowNumber) => {
              if (rowNumber > 6) {
                let obj = {
                  inspection_number: row.getCell(1).value,
                  vehicle_purpose_id: row.getCell(2).value,
                  inspection_date: row.getCell(3).value,
                  brand: row.getCell(4).value,
                  type: row.getCell(5).value,
                  license_plate: row.getCell(6).value,
                  chassis_number: row.getCell(7).value,
                  engine_number: row.getCell(8).value,
                };
                debugger;

                if (!obj.inspection_number) {
                  throw {
                    message: `Cell A${rowNumber} : Nomor uji KIR harus diisi`,
                  };
                }

                if (!obj.vehicle_purpose_id) {
                  throw {
                    message: `Cell B${rowNumber} : Jenis armada harus diisi`,
                  };
                }

                if (!obj.inspection_date) {
                  throw {
                    message: `Cell C${rowNumber} : Tanggal uji KIR harus diisi`,
                  };
                }

                if (!obj.license_plate) {
                  throw {
                    message: `Cell F${rowNumber} : Plat nomor harus diisi`,
                  };
                }

                if (!obj.chassis_number) {
                  throw {
                    message: `Cell G${rowNumber} : Nomor rangka harus diisi`,
                  };
                }

                if (!obj.engine_number) {
                  throw {
                    message: `Cell H${rowNumber} : Nomor mesi harus diisi`,
                  };
                }

                newData.push(obj);
              }
            });

            if (newData && !newData.length) {
              throw {
                message: 'Data excel tidak boleh kosong',
              };
            }

            const {
              data: { importFleet },
            } = await mutate({
              mutation: IMPORT_FLEET,
              variables: {
                data: newData,
              },
            });

            if (importFleet && importFleet.length) {
              setSnack({
                variant: 'success',
                message: 'Eksport data berhasil',
                opened: true,
              });
              window.location.reload();
              return true;
            }
          }
        } catch (error) {
          console.log(error);
          setSnack({
            variant: 'error',
            message: error.message,
            opened: true,
          });
        }
      };
    } catch (error) {
      setSnack({
        variant: 'error',
        message: error.message,
        opened: true,
      });
    }
  };

  const initializeTemplateSheetFormat = (worksheet) => {
    worksheet.getCell('A1').value = 'Import Data KIR Armada';
    worksheet.getCell('A1').font = {
      size: 20,
    };

    worksheet.getCell('A2').value = 'Masukkan data KIR pada file ini.';
    worksheet.getCell('A3').value = 'Kolom dengan tanda (*) wajib diisi.';
    worksheet.getCell('A4').value =
      'Jenis Armada harus diisi ID, daftar ID Jenis bisa dilihat pada worksheet Jenis Armada';

    let cols = [
      'Nomor Uji KIR*',
      'Jenis Armada *',
      'Tanggal Uji KIR *',
      'Merk',
      'Tipe',
      'Plat Nomor *',
      'Nomor Rangka *',
      'Nomor Mesin *',
    ];

    worksheet.getRow(6).values = cols;
    worksheet.columns = cols.map((item) => ({ width: 25 }));
    cols.forEach((item, index) => {
      let currentCell = worksheet.getCell(
        `${String.fromCharCode('A'.charCodeAt() + index)}6`
      );

      currentCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: `00000000` },
      };

      currentCell.font = {
        size: 12,
        color: { argb: 'FFFFFFFF' },
      };
    });

    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 15;
    worksheet.getColumn(8).width = 15;
    worksheet.getColumn(9).width = 15;
    worksheet.getColumn(10).width = 15;
    worksheet.getColumn(11).width = 15;
  };

  const exportFormat = async () => {
    let workbook = new Excel.Workbook();
    workbook.creator = 'Agit Anggriawan';
    workbook.lastModifiedBy = 'Agit Anggriawan';
    workbook.created = new Date();
    workbook.modified = new Date();

    let worksheet = workbook.addWorksheet('Armada');
    initializeTemplateSheetFormat(worksheet);

    let vehiclePurposesWorksheet = workbook.addWorksheet('Jenis Armada');

    let vehiclePurposes = await fetchVehiclePurposes();

    vehiclePurposesWorksheet.getRow(1).values = [
      'ID',
      'Jenis Armada',
      'Keterangan',
    ];
    vehiclePurposesWorksheet.columns = [
      { width: 7 },
      { width: 20 },
      { width: 20 },
    ];
    vehiclePurposesWorksheet.getRow(1).style = { font: { bold: true } };
    let lastIndex = 0;
    if (vehiclePurposes.length > lastIndex) {
      lastIndex = vehiclePurposes.length;
    }

    for (let i = 0; i < lastIndex; i++) {
      let row = [];
      if (vehiclePurposes.length > i) {
        row.push(
          vehiclePurposes[i].id,
          vehiclePurposes[i].name,
          vehiclePurposes[i].description
        );
      } else {
        row.push('', '');
      }

      vehiclePurposesWorksheet.getRow(i + 2).values = row;
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), 'template-import-kir.xlsx');
    });

    return true;
  };

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Armada' }]} />
      {role === 'DISHUB' && (
        <>
          <Box
            sx={{
              display: 'flex',
              mt: 1,
              mb: 3,
            }}
          >
            <Button variant="contained" onClick={exportFormat}>
              Eksport Format Excel
            </Button>
            &nbsp;
            <FleetImportPage handleSave={handleSave} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 1,
              mb: 3,
              justifyContent: 'flex-start',
            }}
          >
            <Chip
              color={chip === 'ALL' ? 'primary' : 'default'}
              label="ALL"
              onClick={() => setChip('ALL')}
              style={{ marginRight: 10 }}
            />
            <Chip
              color={chip === 'CLAIMED' ? 'primary' : 'default'}
              label="CLAIMED"
              onClick={() => setChip('CLAIMED')}
              style={{ marginRight: 10 }}
            />
            <Chip
              color={chip === 'UNCLAIMED' ? 'primary' : 'default'}
              label="UNCLAIMED"
              onClick={() => setChip('UNCLAIMED')}
              style={{ marginRight: 10 }}
            />
            <Chip
              color={chip === 'VALID' ? 'primary' : 'default'}
              label="VALID"
              onClick={() => setChip('VALID')}
              style={{ marginRight: 10 }}
            />
            <Chip
              color={chip === 'EXPIRED' ? 'primary' : 'default'}
              label="EXPIRED"
              onClick={() => setChip('EXPIRED')}
              style={{ marginRight: 10 }}
            />
          </Box>
        </>
      )}
      <MonevTable
        history={history}
        header={header}
        data={fleets}
        title="Data Armada"
        editUrl="fleet"
        editKey="fid"
        editable
        totalData={totalData}
        refetch={refetch}
        offset={offset}
        setOffset={setOffset}
        paginate
      />
    </div>
  );
};

export default FleetPage;
