import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MonevTable from '../MonevTable';
import {
  COMPLAINT_TYPES,
  DELETE_COMPLAINT_TYPE,
} from '../../graphql/ComplaintTypes';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const ComplaintTypePage = (props) => {
  const { query, history, mutate } = props;
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [complainTypes, setComplaintTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [cpid, setCpid] = useState(null);

  useEffect(() => {
    const getUsers = async () => {
      setGlobalLoading(true);
      const {
        data: { complaintTypes },
      } = await query({
        query: COMPLAINT_TYPES,
        fetchPolicy: 'no-cache',
      });

      setComplaintTypes(complaintTypes);
      setGlobalLoading(false);
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setGlobalLoading]);

  const handleClickOpen = (cpid) => {
    console.log(cpid);
    setCpid(cpid);
    setOpen(true);
  };

  const handleClose = () => {
    setCpid(null);
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const old = complainTypes;

      const {
        data: { deleteComplaintType },
      } = await mutate({
        mutation: DELETE_COMPLAINT_TYPE,
        variables: {
          cpid,
        },
      });

      setOpen(false);
      const newBlogs = old.filter((x) => x.cpid !== cpid);
      setComplaintTypes([...newBlogs]);
      setCpid(null);
      setSnack({
        variant: 'success',
        message: `${deleteComplaintType.name} berhasil dihapus`,
        opened: true,
      });
    } catch (error) {
      console.log(error);
      setSnack({
        variant: 'error',
        message: 'Jenis Pengaduan gagal dihapus',
        opened: true,
      });
    }
  };

  const header = [
    { id: 'id', label: 'ID', maxWidth: 10 },
    { id: 'name', label: 'Nama Anggota', minWidth: 170 },
    { id: 'created_at', label: 'Tanggal Dibuat', minWidth: 170 },
    { id: 'edit_driver', label: 'Aksi' },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Jenis Pengaduan' }]} />
      <MonevTable
        history={history}
        header={header}
        data={complainTypes}
        title="Jenis Pengaduan"
        editable={false}
        editUrl="complaint_type"
        editKey="cpid"
        handleClickOpen={handleClickOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin untuk menghapus berita tersebut?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Berita yang telah terhapus tidak bisa ditampilkan lagi
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Tidak
          </Button>
          <Button variant="outlined" onClick={handleDelete} autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ComplaintTypePage;
