import React from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Menu,
  Fade,
  Tooltip,
  Collapse,
  ListItemButton,
} from '@mui/material';
import {
  Menu as MenuIcon,
  // NotificationsActive as NotificationsActiveIcon,
  PowerSettingsNew,
  DirectionsCar as DirectionsCarIcon,
  PeopleAlt as PeopleAltIcon,
  Home as HomeIcon,
  EmojiTransportation as EmojiTransportationIcon,
  Build as BuildIcon,
  Create as CreateIcon,
  Feed as FeedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  ViewCarousel as ViewCarouselIcon,
} from '@mui/icons-material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StorageIcon from '@mui/icons-material/Storage';
import SearchIcon from '@mui/icons-material/Search';
import { Route, Switch, Link, Redirect, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import CompanyPage from './Company/CompanyPage';
import CompanyForm from './Company/CompanyForm';
import CompanyDetail from './Company/CompanyDetail';
import DriverPage from './Driver/DriverPage';
import DriverForm from './Driver/DriverForm';
import DriverDetail from './Driver/DriverDetail';
import DashboardPage from './Dashboard/DashboardPage';
import FleetPage from './Fleet/FleetPage';
import FleetForm from './Fleet/FleetForm';
import FleetSearch from './Fleet/FleetSearch';
import FleetDetail from './Fleet/FleetDetail';
import FleetEdit from './Fleet/FleetEdit';
import SparePartForm from './SparePart/SparePartForm';
import SparePartPage from './SparePart/SparePartPage';
import ComplaintTypePage from './Complaint/ComplaintTypePage';
import ComplaintTypeForm from './Complaint/ComplaintTypeForm';
import ComplaintPage from './Complaint/ComplaintPage';
import NewsForm from './News/NewsForm';
import NewsFormEdit from './News/NewsFormEdit';
import NewsPage from './News/NewsPage';
import CarouselPage from './News/CarouselPage';
import CarouselForm from './News/CarouselForm';
import AnnouncementPage from './News/AnnouncementPage';
import AnnouncementForm from './News/AnnouncementForm';

const drawerWidth = 240;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MonevAdmin = (props) => {
  const { window, history, query, mutate } = props;
  const cookie = new Cookies();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [dropdownFleet, setDropdownFleet] = React.useState(false);
  const [dropdownPart, setDropdownPart] = React.useState(false);
  const [dropdownComplaint, setDropdownComplaint] = React.useState(false);
  const [dropdownInformation, setDropdownInformation] = React.useState(false);
  let queryRouter = useQuery();
  // const title =
  // cookie.get('role') === 'DISHUB' ? 'Admin Page' : 'Perusahaan Otobus';
  // NOTIFICATION
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    cookie.remove('id');
    cookie.remove('name');
    cookie.remove('token', { path: '/' });
    history.replace('/sign_in');
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      {cookie.get('role') === 'DISHUB' && (
        <List>
          <ListItem component={Link} to="/dashboard" button key={'Beranda'}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Beranda'} />
          </ListItem>
          <ListItem component={Link} to="/company" button>
            <ListItemIcon>
              <EmojiTransportationIcon />
            </ListItemIcon>
            <ListItemText primary={'Perusahaan'} />
          </ListItem>
          <ListItem component={Link} to="/fleet" button>
            <ListItemIcon>
              <DirectionsCarIcon />
            </ListItemIcon>
            <ListItemText primary={'Armada'} />
          </ListItem>

          <ListItemButton
            onClick={() => setDropdownComplaint(!dropdownComplaint)}
          >
            <ListItemIcon>
              <FeedIcon />
            </ListItemIcon>
            <ListItemText primary={'Pengaduan'} />
            {dropdownComplaint ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={dropdownComplaint} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/complaint_type"
              >
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText primary="Jenis Pengaduan" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/complaints">
                <ListItemIcon>
                  <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary="Laporan Pengaduan" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            onClick={() => setDropdownInformation(!dropdownInformation)}
          >
            <ListItemIcon>
              <FeedIcon />
            </ListItemIcon>
            <ListItemText primary={'Informasi'} />
            {dropdownInformation ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={dropdownInformation} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/carousel">
                <ListItemIcon>
                  <ViewCarouselIcon />
                </ListItemIcon>
                <ListItemText primary="Carousel" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/blog">
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText primary="Berita" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/announcement"
              >
                <ListItemIcon>
                  <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary="Pengumuman" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      )}

      {cookie.get('role') === 'PO' && (
        <List>
          <ListItem component={Link} to="/dashboard" button key={'Home'}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
          <ListItem component={Link} to="/driver" button>
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary={'Anggota'} />
          </ListItem>
          <ListItemButton onClick={() => setDropdownFleet(!dropdownFleet)}>
            <ListItemIcon>
              <DirectionsCarIcon />
            </ListItemIcon>
            <ListItemText primary={'Armada'} />
            {dropdownFleet ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={dropdownFleet} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/fleet/search"
              >
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Cari Armada" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/fleet">
                <ListItemIcon>
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText primary="Daftar Armada" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={() => setDropdownPart(!dropdownPart)}>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={'Suku Cadang'} />
            {dropdownPart ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={dropdownPart} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 4 }}
                component={Link}
                to="/spare_part/add"
              >
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText primary="Input Suku Cadang" />
              </ListItemButton>
            </List>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} component={Link} to="/spare_part">
                <ListItemIcon>
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText primary="Daftar Suku Cadang" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      )}

      <Divider />
      <List>
        <Tooltip title="Log Out">
          <ListItem button key="Logout" onClick={logout}>
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </Tooltip>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Sistem Informasi Elektronik Keselamatan Angkutan Tulungagung |{' '}
            {cookie.get('name')}
          </Typography>
          <div style={{ position: 'absolute', right: 0, marginRight: 25 }}>
            <IconButton
              color="inherit"
              id="fade-button"
              aria-controls="fade-menu"
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              edge="end"
              onClick={handleClick}
              sx={{ display: { xs: 'none', lg: 'block', xl: 'none' } }}
            >
              {/* <NotificationsActiveIcon /> */}
            </IconButton>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem> */}
              <div style={{ maxWidth: 400 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Rhoncus
              </div>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          {cookie.get('role') === 'DISHUB' && (
            <React.Fragment>
              <Route
                exact
                path="/dashboard"
                render={(props) => (
                  <DashboardPage
                    query={query}
                    token={cookie.get('token')}
                    role={cookie.get('role')}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/company"
                render={(props) => <CompanyPage query={query} {...props} />}
              />
              <Route
                exact
                path="/company/add"
                render={(props) => (
                  <CompanyForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/company/edit/:cid"
                render={(props) => (
                  <CompanyForm
                    query={query}
                    mutate={mutate}
                    editable
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/company/detail/:cid"
                render={(props) => <CompanyDetail query={query} {...props} />}
              />
              <Route
                exact
                path="/company/:cid/fleet/:fid"
                render={(props) => (
                  <FleetDetail query={query} company={true} {...props} />
                )}
              />
              <Route
                exact
                path="/fleet"
                render={(props) => (
                  <FleetPage
                    query={query}
                    mutate={mutate}
                    token={cookie.get('token')}
                    role={cookie.get('role')}
                    status={queryRouter.get('status')}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/fleet/add"
                render={(props) => (
                  <FleetForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/fleet/detail/:fid"
                render={(props) => <FleetDetail query={query} {...props} />}
              />
              <Route
                exact
                path="/fleet/edit/:fid"
                render={(props) => (
                  <FleetEdit query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/complaint_type"
                render={(props) => (
                  <ComplaintTypePage mutate={mutate} query={query} {...props} />
                )}
              />
              <Route
                exact
                path="/complaint_type/add"
                render={(props) => (
                  <ComplaintTypeForm mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/complaint_type/edit/:cpid"
                render={(props) => (
                  <ComplaintTypeForm
                    editable
                    query={query}
                    mutate={mutate}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/complaints"
                render={(props) => <ComplaintPage query={query} {...props} />}
              />
              <Route
                exact
                path="/blog"
                render={(props) => (
                  <NewsPage query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/blog/edit/:bid"
                render={(props) => (
                  <NewsFormEdit query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/blog/add"
                render={(props) => (
                  <NewsForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/carousel"
                render={(props) => (
                  <CarouselPage query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/carousel/add"
                render={(props) => (
                  <CarouselForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/announcement"
                render={(props) => (
                  <AnnouncementPage query={query} {...props} mutate={mutate} />
                )}
              />
              <Route
                exact
                path="/announcement/add"
                render={(props) => (
                  <AnnouncementForm query={query} mutate={mutate} {...props} />
                )}
              />
            </React.Fragment>
          )}

          {cookie.get('role') === 'PO' && (
            <React.Fragment>
              <Route
                exact
                path="/dashboard"
                render={(props) => (
                  <DashboardPage
                    token={cookie.get('token')}
                    query={query}
                    {...props}
                  />
                )}
              />{' '}
              <Route
                exact
                path="/driver"
                render={(props) => <DriverPage query={query} {...props} />}
              />
              <Route
                exact
                path="/driver/add"
                render={(props) => (
                  <DriverForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/driver/edit/:uid"
                render={(props) => (
                  <DriverForm
                    query={query}
                    mutate={mutate}
                    editable
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/driver/detail/:uid"
                render={(props) => <DriverDetail query={query} {...props} />}
              />
              <Route
                exact
                path="/fleet"
                render={(props) => (
                  <FleetPage
                    query={query}
                    mutate={mutate}
                    token={cookie.get('token')}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/fleet/add"
                render={(props) => (
                  <FleetForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/fleet/search"
                render={(props) => (
                  <FleetSearch query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/spare_part/add"
                render={(props) => (
                  <SparePartForm query={query} mutate={mutate} {...props} />
                )}
              />
              <Route
                exact
                path="/spare_part"
                render={(props) => <SparePartPage query={query} {...props} />}
              />
              <Route
                exact
                path="/fleet/detail/:fid"
                render={(props) => <FleetDetail query={query} {...props} />}
              />
              <Route
                exact
                path="/fleet/edit/:fid"
                render={(props) => (
                  <FleetEdit query={query} mutate={mutate} {...props} />
                )}
              />
            </React.Fragment>
          )}
        </Switch>
      </Box>
    </Box>
  );
};

MonevAdmin.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MonevAdmin;
