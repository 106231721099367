import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MonevThankYou = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 10,
        }}
      >
        <img
          alt="logo"
          src="https://tools.atimo.us/images/thanks_email_verification.jpg"
          style={{
            width: '80%',
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 5,
        }}
      >
        <Typography align="center" variant="subtitle2">
          Terima kasih telah mendaftar, silahkan cek kotak masuk di email yang
          sudah Anda daftarkan pada sistem kami untuk mengatur kata sandi dan
          verifikasi akun. <br />
          <a href="/sign_in">Klik disini</a> untuk kembali ke halaman Login.
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default MonevThankYou;
