import { gql } from '@apollo/client';

export const FIND_URBAN_VILLAGE_BY_SUB_DISTRICT_ID = gql`
  query findUrbanVillageBySubDistrictId($sub_district_id: ID!) {
    findUrbanVillageBySubDistrictId(sub_district_id: $sub_district_id) {
      id
      uvid
      name
      created_at
      updated_at
    }
  }
`;
