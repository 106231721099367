/* eslint-disable import/no-anonymous-default-export */
import Compressor from 'compressorjs';

export default async function (files) {
  files = await Promise.all(
    files.map(async (item) => {
      return new Promise((resolve, reject) => {
        new Compressor(item, {
          quality: 0.7,
          maxWidth: 800,
          maxHeight: 800,
          success(result) {
            resolve(result);
          },
        });
      });
    })
  );

  return files;
}
