import { gql } from '@apollo/client';

export const CREATE_BLOG = gql`
  mutation createBlog($data: InBlog!) {
    createBlog(data: $data) {
      id
      bid
      title
      value
      published
      featured
      slug
      slug_hint
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation updateBlog($bid: String!, $data: InBlog!) {
    updateBlog(bid: $bid, data: $data) {
      id
      bid
      title
      value
      published
      featured
      slug
      slug_hint
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;

export const BLOGS = gql`
  query blogs($all: Boolean, $limit: Boolean) {
    blogs(all: $all, limit: $limit) {
      id
      bid
      title
      value
      truncate_value
      published
      featured
      slug
      slug_hint
      image
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;

export const FIND_BLOG_BY_BID = gql`
  query findBlogByBid($bid: String!) {
    findBlogByBid(bid: $bid) {
      id
      bid
      title
      value
      truncate_value
      published
      featured
      slug
      slug_hint
      image
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;

export const TEST_UPLOAD = gql`
  mutation testUpload($file: Upload) {
    testUpload(file: $file)
  }
`;

export const UPDATE_VIEW = gql`
  mutation updateView($bid: String!) {
    updateView(bid: $bid) {
      id
      bid
      title
      value
      published
      featured
      slug
      slug_hint
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;

export const DELETE_BLOG = gql`
  mutation deleteBlog($bid: String!) {
    deleteBlog(bid: $bid) {
      id
      bid
      title
      value
      published
      featured
      slug
      slug_hint
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;

export const FIND_BLOG_BY_SLUG = gql`
  query findBlogBySlug($slug: String!) {
    findBlogBySlug(slug: $slug) {
      id
      bid
      title
      value
      truncate_value
      published
      featured
      slug
      slug_hint
      image
      total_view
      publised_at
      created_at
      updated_at
      user {
        id
        name
      }
    }
  }
`;
