import { gql } from '@apollo/client';

export const CAROUSELS = gql`
  query carousels($all: Boolean) {
    carousels(all: $all) {
      id
      crid
      title
      image
      active
      created_at
      updated_at
    }
  }
`;

export const CREATE_CAROUSEL = gql`
  mutation createCarousel($data: InCarousel!) {
    createCarousel(data: $data) {
      id
      crid
      title
      image
      active
      created_at
      updated_at
    }
  }
`;

export const SET_CAROUSEL = gql`
  mutation setStatusCarousel($crid: String!, $active: Boolean!) {
    setStatusCarousel(crid: $crid, active: $active) {
      id
      crid
      title
      image
      active
      created_at
      updated_at
    }
  }
`;

export const DELETE_CAROUSEL = gql`
  mutation deleteCarousel($crid: String!) {
    deleteCarousel(crid: $crid) {
      id
      crid
      title
      image
      active
      created_at
      updated_at
    }
  }
`;
