import React, { useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloConsumer,
} from '@apollo/client';
import { GlobalProvider } from './utils/GlobalContext';
import { onError } from 'apollo-link-error';
import { createUploadLink as CreateUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookies from 'universal-cookie';
import './App.css';
import MonevRouter from './components/MonevRouter';

const uri =
  process.env.NODE_ENV !== 'production'
    ? process.env.REACT_APP_GRAPHQL_URL_LOCAL
    : process.env.REACT_APP_GRAPHQL_URL_PRODUCTION;

const httpLink = new CreateUploadLink({
  uri,
});

const linkError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) console.log('graphQLErrors App.js: ', graphQLErrors);
  if (networkError) console.log('networkError: ', networkError);
});

const authLink = setContext((_, { headers }) => {
  const cookies = new Cookies();
  const token = cookies.get('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(linkError.concat(httpLink)),
});

let theme = createTheme({
  palette: {
    primary: {
      main: '#2F86A6',
    },
    secondary: {
      main: '#4C4C6D',
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});

function App() {
  const [snack, setSnack] = useState({
    variant: 'success',
    message: null,
    opened: false,
  });
  const [globalLoading, setGlobalLoading] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <GlobalProvider value={{ setSnack, setGlobalLoading }}>
        <ApolloProvider client={client}>
          <ApolloConsumer>
            {(client) => (
              <MonevRouter
                client={client}
                snack={snack}
                globalLoading={globalLoading}
              />
            )}
          </ApolloConsumer>
        </ApolloProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;
