import { gql } from '@apollo/client';

export const CREATE_COMPLAINT = gql`
  mutation createComplaint($data: InComplaint!) {
    createComplaint(data: $data) {
      id
      clid
      complaint_type_id
      title
      description
      incident_date
      location
      contact_person
      created_at
      updated_at
    }
  }
`;

export const COMPLAINTS = gql`
  query complaints {
    complaints {
      id
      clid
      complaint_type_id
      title
      description
      incident_date
      location
      contact_person
      created_at
      updated_at
      complaint_type {
        id
        name
      }
    }
  }
`;
