import { gql } from '@apollo/client';

export const DASHBOARDS = gql`
  query dashboards {
    dashboards {
      total_driver
      total_fleet
      total_company
      total_expired
      total_complaint
      total_carousel
      total_blog
      total_announcement
    }
  }
`;
