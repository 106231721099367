/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Button,
  Container,
  Box,
  Grid,
  CssBaseline,
  TextField,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { CREATE_CAROUSEL } from '../../graphql/Carousels';
import GlobalContext from '../../utils/GlobalContext';
import MonevCompressor from '../MonevCompressor';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const CarouselForm = (props) => {
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [title, setTitle] = useState('');
  const [titleRequired, setTitleRequired] = useState(false);
  const [imageRequired, setImageRequired] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const filesSize = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  const getColor = (props) => {
    if (props.isDragAccept) {
      return '#00e676';
    }
    if (props.isDragReject) {
      return '#ff1744';
    }
    if (props.isDragActive) {
      return '#2196f3';
    }
    return '#eeeeee';
  };

  const handleSave = async () => {
    try {
      setDisabled(true);
      setGlobalLoading(true);

      if (!title && !acceptedFiles.length) {
        setTitleRequired(true);
        setImageRequired(true);
        setDisabled(false);
        setGlobalLoading(false);
        return false;
      } else if (!title) {
        setTitleRequired(true);
        setDisabled(false);
        setGlobalLoading(false);
        return false;
      } else if (!acceptedFiles.length) {
        setImageRequired(true);
        setDisabled(false);
        setGlobalLoading(false);
        return false;
      }

      const sizeImgs = acceptedFiles.filter((x) => x.size > 3145728);

      if (sizeImgs && sizeImgs.length) {
        setSnack({
          variant: 'error',
          message: 'Ukuran foto maksimal 3 Megabytes.',
          opened: true,
        });

        setDisabled(false);
        setGlobalLoading(false);
        return false;
      }

      const compressImg = await MonevCompressor(acceptedFiles);

      const result = await Promise.all(
        compressImg.map(async (file) => {
          return new Promise((resolve, reject) => {
            let newImage = { url: file };
            let reader = new FileReader();

            reader.onload = function (e) {
              newImage.preview = e.target.result;
              resolve(newImage);
            };

            reader.readAsDataURL(file);
          });
        })
      );

      await mutate({
        mutation: CREATE_CAROUSEL,
        variables: {
          data: {
            title,
            image: result[0].url,
          },
        },
      });

      setSnack({
        variant: 'success',
        message: 'Carousel berhasil ditambah',
        opened: true,
      });

      setGlobalLoading(false);
      setDisabled(false);
      history.replace('/carousel');
    } catch (error) {
      setSnack({
        variant: 'error',
        message: 'Carousel gagal ditambah',
        opened: true,
      });

      setGlobalLoading(false);
      setDisabled(false);
    }
  };

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Carousel' }]} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="title"
                  name="title"
                  required
                  fullWidth
                  id="title"
                  label="Ketarangan Carousel"
                  autoFocus
                  value={title}
                  error={titleRequired}
                  helperText={titleRequired && 'Harus diisi'}
                  onChange={(e) => {
                    setTitleRequired(false);
                    setTitle(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4>Unggah Gambar</h4>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: '2px',
                    borderRadius: '2px',
                    borderStyle: 'dashed',
                    borderColor: (props) => getColor(props),
                    backgroundColor: '#fafafa',
                    color: '#bdbdbd',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                  }}
                  {...getRootProps({
                    className: 'dropzone',
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  })}
                >
                  <input {...getInputProps()} />
                  <p style={{ textAlign: 'center' }}>
                    Klik untuk menggunggah foto. <br />
                    Ukuran foto maksimal 3 Megabytes
                  </p>
                </div>
                <aside style={thumbsContainer}>{thumbs}</aside>
                <aside>
                  <ul>{filesSize}</ul>
                </aside>
                {imageRequired && !acceptedFiles.length && (
                  <p style={{ color: 'red', fontSize: '12px' }}>Harus diisi</p>
                )}
              </Grid>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSave()}
                disabled={disabled}
              >
                Simpan
              </Button>{' '}
              &nbsp;
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
                disabled={disabled}
                onClick={() => history.replace('/carousel')}
              >
                Kembali
              </Button>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default CarouselForm;
