import { gql } from '@apollo/client';

export const FIND_USER_BY_TOKEN = gql`
  query findUserByToken($token: String!) {
    findUserByToken(token: $token) {
      id
      email
      token
      status_token
    }
  }
`;

export const VERIFY_USER = gql`
  mutation verifyUser(
    $token: String!
    $password: String!
    $password_confirmation: String!
  ) {
    verifyUser(
      token: $token
      password: $password
      password_confirmation: $password_confirmation
    ) {
      id
      uid
      role_id
      email
    }
  }
`;

export const AUTHENTICATE = gql`
  query authenticate($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      email
      role
      name
      uid
      token
      id
      company_id
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation createMember($data: InMember!) {
    createMember(data: $data) {
      id
      uid
      name
    }
  }
`;

export const FIND_USER_BY_COMPANY_ID = gql`
  query findUserByCompanyId($company_id: ID!) {
    findUserByCompanyId(company_id: $company_id) {
      id
      uid
      name
      handphone
      role {
        name
        code
      }
    }
  }
`;

export const FIND_USER_BY_UID = gql`
  query findUserByUid($uid: String!) {
    findUserByUid(uid: $uid) {
      id
      uid
      name
      handphone
      role_id
      driver_license
      role {
        id
        name
        code
      }
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation updateMember($data: InMember!, $uid: String!) {
    updateMember(data: $data, uid: $uid) {
      id
      uid
      name
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      id
      email
    }
  }
`;
