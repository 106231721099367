import { gql } from '@apollo/client';

export const COMPLAINT_TYPES = gql`
  query complaintTypes {
    complaintTypes {
      id
      cpid
      name
      active
      created_at
      updated_at
      complaints {
        id
      }
    }
  }
`;

export const FIND_COMPLAINT_TYPE_BY_CPID = gql`
  query findComplaintTypeByCpid($cpid: String!) {
    findComplaintTypeByCpid(cpid: $cpid) {
      id
      cpid
      name
      active
      created_at
      updated_at
    }
  }
`;

export const CREATE_COMPLAINT_TYPE = gql`
  mutation createComplaintType($data: InComplaintType!) {
    createComplaintType(data: $data) {
      id
      cpid
      name
      active
      created_at
      updated_at
    }
  }
`;

export const UPDATE_COMPLAINT_TYPE = gql`
  mutation updateComplaintType($cpid: String!, $data: InComplaintType!) {
    updateComplaintType(cpid: $cpid, data: $data) {
      id
      cpid
      name
      active
      created_at
      updated_at
    }
  }
`;

export const DELETE_COMPLAINT_TYPE = gql`
  mutation deleteComplaintType($cpid: String!) {
    deleteComplaintType(cpid: $cpid) {
      id
      cpid
      name
      active
      created_at
      updated_at
    }
  }
`;
