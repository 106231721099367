import { gql } from '@apollo/client';

export const REGISTRATION = gql`
  mutation registration($data: InRegistration!) {
    registration(data: $data) {
      id
      cid
      name
      address
      phone
      description
      active
      created_at
      updated_at
    }
  }
`;

export const FIND_COMPANY_BY_EMAIL = gql`
  query findCompanyByEmail($email: String) {
    findCompanyByEmail(email: $email) {
      id
      cid
      name
      address
      phone
      description
      active
      created_at
      updated_at
    }
  }
`;

export const COMPANIES = gql`
  query companies {
    companies {
      id
      cid
      name
      address
      phone
      description
      active
      created_at
      updated_at
    }
  }
`;

export const FIND_COMPANY_BY_CID = gql`
  query findCompanyByCid($cid: String!) {
    findCompanyByCid(cid: $cid) {
      id
      cid
      urban_village_id
      service_type_id
      name
      address
      phone
      description
      npwp
      responsible_phone
      active
      email
      created_at
      updated_at
      total_user
      total_fleet
      total_fleet_expired
      urban_village {
        id
        name
        sub_district {
          id
          name
        }
      }
      service_type {
        name
      }
      users {
        id
        name
        email
        handphone
        driver_license
        created_at
        role {
          name
          code
        }
      }
      fleets {
        id
        fid
        company_id
        vehicle_purpose_id
        inspection_number
        license_plate
        chassis_number
        engine_number
        brand
        type
        inspection_date
        end_of_inspection_date
        expired
        vehicle_purpose {
          name
        }
        spare_parts {
          id
          sid
          fleet_id
          part
          created_at
        }
        created_at
        extra
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($cid: String!, $data: InRegistration!) {
    updateCompany(cid: $cid, data: $data) {
      id
      cid
      name
      address
      phone
      description
      active
      created_at
      updated_at
    }
  }
`;
