import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'universal-cookie/cjs';
import MonevTable from '../MonevTable';
import { FIND_USER_BY_COMPANY_ID } from '../../graphql/User';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const DriverPage = (props) => {
  const { query, history } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const cookie = new Cookies();

  useEffect(() => {
    const getUsers = async () => {
      setGlobalLoading(true);
      const {
        data: { findUserByCompanyId },
      } = await query({
        query: FIND_USER_BY_COMPANY_ID,
        variables: {
          company_id: cookie.get('cid'),
        },
        fetchPolicy: 'no-cache',
      });

      setUsers(findUserByCompanyId);
      setGlobalLoading(false);
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setGlobalLoading]);

  const header = [
    { id: 'id', label: 'ID', minWidth: 10 },
    { id: 'name', label: 'Nama Anggota', minWidth: 170 },
    { id: 'role.code', label: 'Jenis', minWidth: 100 },
    { id: 'handphone', label: 'Handphone', minWidth: 100 },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Anggota' }]} />
      <MonevTable
        history={history}
        header={header}
        data={users}
        title="Data Sopir / Kernet"
        editable={true}
        editUrl="driver"
        editKey="uid"
      />
    </div>
  );
};

export default DriverPage;
