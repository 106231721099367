import { gql } from '@apollo/client';

export const ANNOUNCEMENTS = gql`
  query announcements($limit: Boolean = false, $all: Boolean) {
    announcements(limit: $limit, all: $all) {
      id
      aid
      title
      description
      document
      active
      created_at
      updated_at
    }
  }
`;

export const FIND_ANNOUNCEMENT_BY_AID = gql`
  query findAnnouncementByAid($aid: String!) {
    findAnnouncementByAid(aid: $aid) {
      id
      aid
      title
      description
      document
      active
      created_at
      updated_at
    }
  }
`;

export const CREATE_ANNOUNCEMENT = gql`
  mutation createAnnouncement($data: InAnnouncement!) {
    createAnnouncement(data: $data) {
      id
      aid
      title
      document
      description
      active
      created_at
      updated_at
    }
  }
`;

export const SET_ANNOUNCEMENT = gql`
  mutation updateStatusAnnouncement($aid: String!, $active: Boolean!) {
    updateStatusAnnouncement(aid: $aid, active: $active) {
      id
      aid
      title
      document
      description
      active
      created_at
      updated_at
    }
  }
`;
