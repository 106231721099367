import { gql } from '@apollo/client';

export const SUB_DISTRICTS = gql`
  query subDistricts {
    subDistricts {
      id
      sdid
      name
      created_at
      updated_at
    }
  }
`;

export const FIND_SUB_DISTRICT_BY_SDID = gql`
  query findSubDistrictBySdid($sdid: String!) {
    findSubDistrictBySdid(sdid: $sdid) {
      id
      sdid
      name
      created_at
      updated_at
    }
  }
`;
