import React, { useState, useContext } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Button,
  Container,
  Box,
  Grid,
  CssBaseline,
  TextField,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useDropzone } from 'react-dropzone';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { CREATE_BLOG } from '../../graphql/Blog';
import GlobalContext from '../../utils/GlobalContext';
import MonevCompressor from '../MonevCompressor';

const NewsForm = (props) => {
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [featured, setFeatured] = useState(false);
  const [published, setPublished] = useState(true);

  const onEditorStateChange = (editorState) => {
    // const contentState = editorState.getCurrentContent();
    // console.log('editorState', editorState);
    // console.log('content state', convertToRaw(contentState));
    setEditorState(editorState);
  };

  const truncate = (editorState, charCount = 200) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();

    let index = 0;
    let currentLength = 0;
    let isTruncated = false;
    const truncatedBlocks = [];

    while (!isTruncated && blocks[index]) {
      const block = blocks[index];
      const length = block.getLength();
      if (currentLength + length > charCount) {
        isTruncated = true;
        const truncatedText = block
          .getText()
          .slice(0, charCount - currentLength);
        const state = ContentState.createFromText(`${truncatedText}...`);
        truncatedBlocks.push(state.getFirstBlock());
      } else {
        truncatedBlocks.push(block);
      }
      currentLength += length + 1;
      index++;
    }

    if (isTruncated) {
      const state = ContentState.createFromBlockArray(truncatedBlocks);
      return EditorState.createWithContent(state);
    }

    return editorState;
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleSave = async () => {
    try {
      const sizeImgs = acceptedFiles.filter((x) => x.size > 3145728);

      if (sizeImgs && sizeImgs.length) {
        setSnack({
          variant: 'error',
          message: 'Ukuran foto maksimal 3 Megabytes.',
          opened: true,
        });

        return false;
      }

      const compressImg = await MonevCompressor(acceptedFiles);
      const result = await Promise.all(
        compressImg.map(async (file) => {
          return new Promise((resolve, reject) => {
            let newImage = { url: file };
            let reader = new FileReader();

            reader.onload = function (e) {
              newImage.preview = e.target.result;
              resolve(newImage);
            };

            reader.readAsDataURL(file);
          });
        })
      );

      const contentState = editorState.getCurrentContent();
      const raw = convertToRaw(contentState);

      const truc = truncate(editorState);
      const trucContentState = truc.getCurrentContent();
      const trucRaw = convertToRaw(trucContentState);

      const param = {
        title,
        value: raw,
        truncate_value: trucRaw,
        published,
        featured,
      };

      if (result && result.length) {
        param.image = result[0].url;
      }

      const {
        data: { createBlog },
      } = await mutate({
        mutation: CREATE_BLOG,
        variables: {
          data: param,
        },
      });

      setSnack({
        variant: 'success',
        message: `Berita ${createBlog.title} berhasil ditambah`,
        opened: true,
      });

      setGlobalLoading(false);
      history.replace('/blog');
    } catch (error) {
      setSnack({
        variant: 'error',
        message: 'Berita gagal ditambah',
        opened: true,
      });

      setGlobalLoading(false);
    }
  };

  const getColor = (props) => {
    if (props.isDragAccept) {
      return '#00e676';
    }
    if (props.isDragReject) {
      return '#ff1744';
    }
    if (props.isDragActive) {
      return '#2196f3';
    }
    return '#eeeeee';
  };

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Berita' }]} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="title"
                  name="title"
                  required
                  fullWidth
                  id="title"
                  label="Judul Berita"
                  autoFocus
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4>Unggah Gambar</h4>
                <section className="container">
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '20px',
                      borderWidth: '2px',
                      borderRadius: '2px',
                      borderStyle: 'dashed',
                      borderColor: (props) => getColor(props),
                      backgroundColor: '#fafafa',
                      color: '#bdbdbd',
                      outline: 'none',
                      transition: 'border .24s ease-in-out',
                    }}
                    {...getRootProps({
                      className: 'dropzone',
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    })}
                  >
                    <input {...getInputProps()} />
                    <p style={{ textAlign: 'center' }}>
                      Klik untuk menggunggah foto. <br />
                      Ukuran foto maksimal 3 Megabytes
                    </p>
                  </div>
                  <aside>
                    <ul>{files}</ul>
                  </aside>
                </section>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4>Konten Berita</h4>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: '2px',
                    borderRadius: '2px',
                    borderStyle: 'dashed',
                    backgroundColor: '#fafafa',
                    color: 'black',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                  }}
                >
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        value={featured}
                        onChange={(e) => setFeatured(e.target.checked)}
                      />
                    }
                    label="Berita Utama"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        value={published}
                        onChange={(e) => setPublished(e.target.checked)}
                      />
                    }
                    label="Publikasi"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSave()}
                >
                  Simpan
                </Button>{' '}
                &nbsp;
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => history.replace('/blog')}
                >
                  Kembali
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default NewsForm;
