/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from 'react';
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  Button,
  Container,
  Box,
  Grid,
  CssBaseline,
  TextField,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { UPDATE_BLOG, FIND_BLOG_BY_BID } from '../../graphql/Blog';
import GlobalContext from '../../utils/GlobalContext';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const NewsForm = (props) => {
  const { mutate, history, query, match } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [featured, setFeatured] = useState(null);
  const [published, setPublished] = useState(null);
  const [files, setFiles] = useState([]);
  const [image, setImage] = useState(null);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  React.useEffect(() => {
    const getBlog = async () => {
      setGlobalLoading(true);
      const {
        data: { findBlogByBid },
      } = await query({
        query: FIND_BLOG_BY_BID,
        variables: {
          bid: match.params.bid,
        },
        fetchPolicy: 'no-cache',
      });
      setFeatured(findBlogByBid.featured);
      setPublished(findBlogByBid.published);
      setTitle(findBlogByBid.title);
      setImage(findBlogByBid.image);
      const contentValue = convertFromRaw(findBlogByBid['value']);

      const valueState = EditorState.createWithContent(contentValue);

      setEditorState(valueState);
      setGlobalLoading(false);
    };

    getBlog();
  }, [match.params.bid, query, setGlobalLoading]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const truncate = (editorState, charCount = 200) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlocksAsArray();

    let index = 0;
    let currentLength = 0;
    let isTruncated = false;
    const truncatedBlocks = [];

    while (!isTruncated && blocks[index]) {
      const block = blocks[index];
      const length = block.getLength();
      if (currentLength + length > charCount) {
        isTruncated = true;
        const truncatedText = block
          .getText()
          .slice(0, charCount - currentLength);
        const state = ContentState.createFromText(`${truncatedText}...`);
        truncatedBlocks.push(state.getFirstBlock());
      } else {
        truncatedBlocks.push(block);
      }
      currentLength += length + 1;
      index++;
    }

    if (isTruncated) {
      const state = ContentState.createFromBlockArray(truncatedBlocks);
      return EditorState.createWithContent(state);
    }

    return editorState;
  };

  const handleSave = async () => {
    try {
      const result = await Promise.all(
        acceptedFiles.map(async (file) => {
          return new Promise((resolve, reject) => {
            let newImage = { url: file };
            let reader = new FileReader();

            reader.onload = function (e) {
              newImage.preview = e.target.result;
              resolve(newImage);
            };

            reader.readAsDataURL(file);
          });
        })
      );

      const contentState = editorState.getCurrentContent();
      const raw = convertToRaw(contentState);

      const truc = truncate(editorState);
      const trucContentState = truc.getCurrentContent();
      const trucRaw = convertToRaw(trucContentState);

      const param = {
        title,
        value: raw,
        truncate_value: trucRaw,
        published,
        featured,
      };

      if (result && result.length) {
        param.image = result[0].url;
      }

      const {
        data: { updateBlog },
      } = await mutate({
        mutation: UPDATE_BLOG,
        variables: {
          bid: match.params.bid,
          data: param,
        },
      });

      setSnack({
        variant: 'success',
        message: `Berita ${updateBlog.title} berhasil diubah`,
        opened: true,
      });

      setGlobalLoading(false);
      history.replace('/blog');
    } catch (error) {
      setSnack({
        variant: 'error',
        message: 'Berita gagal ditambah',
        opened: true,
      });

      setGlobalLoading(false);
    }
  };

  const filesSize = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  React.useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Berita' }]} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="title"
                  name="title"
                  required
                  fullWidth
                  id="title"
                  label="Judul Berita"
                  autoFocus
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4>Gambar Berita</h4>
                <section className="container">
                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      padding: '20px',
                      borderWidth: '2px',
                      borderRadius: '2px',
                      borderStyle: 'dashed',
                      backgroundColor: '#fafafa',
                      color: '#bdbdbd',
                      outline: 'none',
                      transition: 'border .24s ease-in-out',
                    }}
                    {...getRootProps({ className: 'dropzone' })}
                  >
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                  <aside style={thumbsContainer}>{thumbs}</aside>
                  {files && !files.length && (
                    <>
                      <h5>Thumbnail</h5>
                      <aside style={thumbsContainer}>
                        <div style={thumb} key={0}>
                          <div style={thumbInner}>
                            <img src={image} style={img} />
                          </div>
                        </div>
                      </aside>
                    </>
                  )}
                  <aside>
                    <ul>{filesSize}</ul>
                  </aside>
                </section>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4>Konten Berita</h4>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: '2px',
                    borderRadius: '2px',
                    borderStyle: 'dashed',
                    backgroundColor: '#fafafa',
                    color: 'black',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                  }}
                >
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={featured}
                        value={featured}
                        onChange={(e) => setFeatured(e.target.checked)}
                      />
                    }
                    label="Berita Utama"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={published}
                        value={published}
                        onChange={(e) => setPublished(e.target.checked)}
                      />
                    }
                    label="Publikasi"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => handleSave()}
                >
                  Simpan
                </Button>{' '}
                &nbsp;
                <Button
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => history.replace('/blog')}
                >
                  Kembali
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default NewsForm;
