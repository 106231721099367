import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MonevTable from '../MonevTable';
import {
  CAROUSELS,
  SET_CAROUSEL,
  DELETE_CAROUSEL,
} from '../../graphql/Carousels';
import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const CarouselPage = (props) => {
  const { query, history, token, mutate } = props;
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [carousels, setCarousels] = useState([]);
  const [open, setOpen] = useState(false);
  const [crid, setCrid] = useState(null);

  useEffect(() => {
    const getCarousels = async () => {
      setGlobalLoading(true);
      const {
        data: { carousels },
      } = await query({
        query: CAROUSELS,
        variables: {
          all: true,
        },
        fetchPolicy: 'no-cache',
      });

      setCarousels(carousels);
      setGlobalLoading(false);
    };

    getCarousels();
  }, [query, setGlobalLoading, token]);

  const handleUpdate = async (active, crid) => {
    await mutate({
      mutation: SET_CAROUSEL,
      variables: {
        crid,
        active,
      },
    });

    const old = carousels;
    const newData = old.map((x) => {
      if (x.crid === crid) {
        return {
          ...x,
          active,
        };
      }

      return x;
    });
    setCarousels([...newData]);
    setSnack({
      variant: 'success',
      message: 'Status Carousel berhasil diupdate',
      opened: true,
    });

    setGlobalLoading(false);
    history.replace('/carousel');
  };

  const handleClickOpen = (crid) => {
    setCrid(crid);
    setOpen(true);
  };

  const handleClose = () => {
    setCrid(null);
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const old = carousels;

      const {
        data: { deleteCarousel },
      } = await mutate({
        mutation: DELETE_CAROUSEL,
        variables: {
          crid,
        },
      });

      setOpen(false);
      const newBlogs = old.filter((x) => x.crid !== crid);
      setCarousels([...newBlogs]);
      setCrid(null);
      setSnack({
        variant: 'success',
        message: `${deleteCarousel.title} berhasil dihapus`,
        opened: true,
      });
    } catch (error) {
      setSnack({
        variant: 'error',
        message: 'Carousel gagal ditambah',
        opened: true,
      });
    }
  };

  const header = [
    { id: 'id', label: 'ID', minWidth: 50 },
    { id: 'title', label: 'Keterangan Carousel' },
    { id: 'active', label: 'Status' },
    { id: 'created_at', label: 'Tanggal Rilis' },
    { id: 'action', label: 'Aksi', align: 'center' },
  ];

  return (
    <div>
      <MonevBreadcrumbs item={[{ title: 'Carousel' }]} />
      <MonevTable
        history={history}
        header={header}
        data={carousels}
        title="Data Carousel"
        editUrl="carousel"
        editKey="crid"
        handleUpdate={handleUpdate}
        handleClickOpen={handleClickOpen}
        handleDelete={handleDelete}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin untuk menghapus carousel tersebut?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Carousel yang telah terhapus tidak bisa ditampilkan lagi
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Tidak
          </Button>
          <Button variant="outlined" onClick={handleDelete} autoFocus>
            Ya
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CarouselPage;
