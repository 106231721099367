import React, { useState, useContext } from 'react';
import {
  Stack,
  Paper,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Box,
  TextField,
  Button,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';
import {
  FIND_FLEET_BY_INSPECTION_NUMBER,
  CLAIM_FLEET,
} from '../../graphql/Fleets';

import GlobalContext from '../../utils/GlobalContext';
import MonevBreadcrumbs from '../MonevBreadcumbs';

const FleetSearch = (props) => {
  const { query, history, mutate } = props;
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  const [fleet, setFleet] = useState(null);
  const [inspectionNumber, setInspectionNumber] = useState('');
  const [showError, setShowError] = useState(false);

  const searchFleet = async () => {
    const {
      data: { findFleetByInspectionNumber },
    } = await query({
      query: FIND_FLEET_BY_INSPECTION_NUMBER,
      variables: {
        inspection_number: inspectionNumber,
      },
      fetchPolicy: 'no-cache',
    });

    if (findFleetByInspectionNumber) {
      setFleet(findFleetByInspectionNumber);
    } else {
      setSnack({
        variant: 'error',
        message:
          'Nomor KIR tidak valid, belum terdaftar dalam sistem, atau sudah diklaim perusahaan',
        opened: true,
      });
    }
  };

  const handleReset = () => {
    setInspectionNumber('');
    setFleet(null);
  };

  const handleClaim = async () => {
    try {
      setGlobalLoading(true);

      const {
        data: { claimFleet },
      } = await mutate({
        mutation: CLAIM_FLEET,
        variables: {
          fid: fleet.fid,
        },
      });

      if (claimFleet) {
        setSnack({
          variant: 'success',
          message: 'Klaim armada berhasil',
          opened: true,
        });
        setGlobalLoading(false);
      }
      history.replace('/fleet');
    } catch (error) {
      console.log('error', error);
      setSnack({
        variant: 'error',
        message: 'Klaim armada gagal, silahkan coba lagi.',
        opened: true,
      });
      setGlobalLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <MonevBreadcrumbs
          item={[{ title: 'Armada' }, { title: 'Cari Armada' }]}
        />
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControl size="small" margin="dense">
                <OutlinedInput
                  name="search"
                  margin="dense"
                  autoFocus
                  id="search"
                  placeholder="Ketik Nomor Uji KIR"
                  onChange={(e) => {
                    setInspectionNumber(e.target.value);
                    setShowError(false);
                  }}
                  value={inspectionNumber}
                  error={showError}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          if (inspectionNumber) {
                            searchFleet();
                          } else {
                            setShowError(true);
                          }
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {showError && (
                  <FormHelperText error>Harus diisi</FormHelperText>
                )}
              </FormControl>
              <Tooltip title="Contoh Nomor Uji KIR: TA 1234">
                <IconButton
                  sx={{
                    mt: 1,
                    ml: 1,
                  }}
                >
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      {fleet && (
        <Box>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="extra_owner"
                  fullWidth
                  id="extra_owner"
                  size="small"
                  label="Nama Pemilik"
                  disabled
                  value={fleet?.extra?.owner}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="vehicle_purpose"
                  fullWidth
                  id="vehicle_purpose"
                  size="small"
                  label="Jenis Kendaraan"
                  disabled
                  value={fleet?.vehicle_purpose?.name}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="extra_address"
                  fullWidth
                  id="extra_address"
                  size="small"
                  label="Alamat"
                  disabled
                  value={fleet?.extra?.address}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="extra_city"
                  fullWidth
                  id="extra_city"
                  size="small"
                  label="Alamat"
                  disabled
                  value={fleet?.extra?.city}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="inspection_number"
                  fullWidth
                  id="inspection_number"
                  size="small"
                  label="Nomor Uji KIR"
                  disabled
                  value={fleet?.inspection_number}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="license_plate"
                  fullWidth
                  id="license_plate"
                  size="small"
                  label="Plat Nomor"
                  disabled
                  value={fleet?.license_plate}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="chassis_number"
                  fullWidth
                  id="chassis_number"
                  size="small"
                  label="Nomor Rangka"
                  disabled
                  value={fleet?.chassis_number}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="engine_number"
                  fullWidth
                  id="engine_number"
                  size="small"
                  label="Nomor Mesin"
                  disabled
                  value={fleet?.engine_number}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="type"
                  fullWidth
                  id="brand"
                  size="small"
                  label="Merek"
                  disabled
                  value={fleet?.brand}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="type"
                  fullWidth
                  id="type"
                  size="small"
                  label="Type"
                  disabled
                  value={fleet?.type}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="inspection_date"
                  fullWidth
                  id="inspection_date"
                  size="small"
                  label="Tanggal Uji KIR"
                  disabled
                  value={fleet?.inspection_date}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  name="end_of_inspection_date"
                  fullWidth
                  id="end_of_inspection_date"
                  size="small"
                  label="Masa Berlaku KIR"
                  disabled
                  value={fleet?.end_of_inspection_date}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleClaim}
            >
              Klaim
            </Button>{' '}
            &nbsp;
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default FleetSearch;
