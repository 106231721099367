import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Alert,
} from '@mui/material';
import { DASHBOARDS } from '../../graphql/Dashboards';
import GlobalContext from '../../utils/GlobalContext';

const DashboardPage = (props) => {
  const { query, token, role, history } = props;
  const [dashboard, setDashboard] = useState({
    total_driver: 0,
    total_fleet: 0,
    total_company: 0,
    total_expired: 0,
    total_complaint: 0,
    total_carousel: 0,
    total_blog: 0,
    total_announcement: 0,
  });
  const { setGlobalLoading } = useContext(GlobalContext);

  useEffect(() => {
    const getDashboard = async () => {
      setGlobalLoading(true);
      const {
        data: { dashboards },
      } = await query({
        query: DASHBOARDS,
        fetchPolicy: 'no-cache',
      });

      setDashboard({ ...dashboards });
      setGlobalLoading(false);
    };

    getDashboard();
  }, [props.match.params.uid, query, setGlobalLoading, token]);

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 4 }}>
      <Grid container spacing={3}>
        {role === 'DISHUB' && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Alert
              severity="warning"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => history.push('/fleet?status=expired')}
                >
                  Lihat Detail
                </Button>
              }
            >
              Terdapat data {dashboard?.total_expired} KIR Kedaluwarsa
            </Alert>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 120,
            }}
          >
            <Typography variant="h6" align="center" noWrap>
              Total Anggota
            </Typography>
            <Typography variant="h3" align="center" noWrap>
              {dashboard?.total_driver}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 120,
            }}
          >
            <Typography variant="h6" align="center" noWrap>
              Total Armada
            </Typography>
            <Typography variant="h3" align="center" noWrap>
              {dashboard?.total_fleet}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 120,
            }}
          >
            <Typography variant="h6" align="center" noWrap>
              Total KIR Kedaluwarsa
            </Typography>
            <Typography variant="h3" align="center" noWrap>
              {dashboard?.total_expired}
            </Typography>
          </Paper>
        </Grid>
        {role === 'DISHUB' && (
          <React.Fragment>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 120,
                }}
              >
                <Typography variant="h6" align="center" noWrap>
                  Total Pengaduan
                </Typography>
                <Typography variant="h3" align="center" noWrap>
                  {dashboard?.total_complaint}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 120,
                }}
              >
                <Typography variant="h6" align="center" noWrap>
                  Total Carousel
                </Typography>
                <Typography variant="h3" align="center" noWrap>
                  {dashboard?.total_carousel}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 120,
                }}
              >
                <Typography variant="h6" align="center" noWrap>
                  Total Berita
                </Typography>
                <Typography variant="h3" align="center" noWrap>
                  {dashboard?.total_blog}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 120,
                }}
              >
                <Typography variant="h6" align="center" noWrap>
                  Total Pengumuman
                </Typography>
                <Typography variant="h3" align="center" noWrap>
                  {dashboard?.total_announcement}
                </Typography>
              </Paper>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Container>
  );
};

export default DashboardPage;
