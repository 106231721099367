import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import GlobalContext from '../../utils/GlobalContext';
import { SPARE_PARTS } from '../../graphql/SpareParts';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.fleet?.vehicle_purpose?.name}
        </TableCell>
        <TableCell align="right">{row.fleet?.license_plate}</TableCell>
        <TableCell align="right">{row.fleet?.brand}</TableCell>
        <TableCell align="right">{row.fleet?.type}</TableCell>
        <TableCell align="right">{row.created_at}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Penggantian Suku Cadang
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Suku Cadang</TableCell>
                    <TableCell>Merk</TableCell>
                    <TableCell>Kilometer</TableCell>
                    <TableCell>Tanggal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="oil">
                    <TableCell component="th" scope="row">
                      Oli Mesin
                    </TableCell>
                    <TableCell>
                      {!row.part?.oil.status ? row.part?.oil.brand : '-'}
                    </TableCell>
                    <TableCell>
                      {!row.part?.oil.status
                        ? Number(row.part?.oil.km).toLocaleString('id-ID')
                        : '-'}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: !row.part?.oil.status
                          ? '#fff176'
                          : null,
                      }}
                    >
                      {!row.part?.oil.status
                        ? dayjs(row.part?.oil.date).format('DD-MM-YYYY')
                        : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow key="oil_filter">
                    <TableCell component="th" scope="row">
                      Filter Oli
                    </TableCell>
                    <TableCell>
                      {!row.part?.oil_filter.status
                        ? row.part?.oil_filter.brand
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {!row.part?.oil_filter.status
                        ? Number(row.part?.oil_filter.km).toLocaleString(
                            'id-ID'
                          )
                        : '-'}
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: row.part?.oil_filter.date
                          ? '#fff176'
                          : null,
                      }}
                    >
                      {row.part?.oil_filter.date
                        ? dayjs(row.part?.oil_filter.date).format('DD-MM-YYYY')
                        : '-'}
                    </TableCell>
                  </TableRow>
                  {!row.part?.tire?.skipped &&
                    row.part?.tire?.tires?.map((x, i) => (
                      <TableRow key="tire">
                        <TableCell component="th" scope="row">
                          {x.name}
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>
                          {x.km ? Number(x.km).toLocaleString('id-ID') : '-'}
                        </TableCell>
                        <TableCell
                          style={{
                            backgroundColor: x.date ? '#fff176' : null,
                          }}
                        >
                          {x.date ? dayjs(x.date).format('DD-MM-YYYY') : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!row.part?.extra.skipped &&
                    row.part?.extra?.extras.map((x, i) => (
                      <TableRow key="oil_filter">
                        <TableCell component="th" scope="row">
                          {x.name}
                        </TableCell>
                        <TableCell>-</TableCell>
                        <TableCell>-</TableCell>
                        <TableCell
                          style={{
                            backgroundColor: x.date ? '#fff176' : null,
                          }}
                        >
                          {x.date ? dayjs(x.date).format('DD-MM-YYYY') : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollapsibleTable(props) {
  const { query } = props;
  const { setGlobalLoading } = useContext(GlobalContext);
  const [spareParts, setSpareParts] = useState([]);

  useEffect(() => {
    const getSpareParts = async () => {
      setGlobalLoading(true);
      const {
        data: { spareParts },
      } = await query({
        query: SPARE_PARTS,
        fetchPolicy: 'no-cache',
      });

      setSpareParts(spareParts);
      setGlobalLoading(false);
    };

    getSpareParts();
  }, [query, setGlobalLoading]);

  return (
    <React.Fragment>
      <MonevBreadcrumbs item={[{ title: 'Suku Cadang' }]} />

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Jenis</TableCell>
              <TableCell align="right">Plat Nomor</TableCell>
              <TableCell align="right">Merk</TableCell>
              <TableCell align="right">Tipe</TableCell>
              <TableCell align="right">Tanggal Dibuat</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {spareParts.map((row) => (
              <Row key={row.name} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}
