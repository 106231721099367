import React, { useState, useContext } from 'react';
import {
  Button,
  Container,
  Box,
  Grid,
  CssBaseline,
  TextField,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { CREATE_ANNOUNCEMENT } from '../../graphql/Announcements';
import GlobalContext from '../../utils/GlobalContext';

const NewsForm = (props) => {
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'application/pdf',
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const getColor = (props) => {
    if (props.isDragAccept) {
      return '#00e676';
    }
    if (props.isDragReject) {
      return '#ff1744';
    }
    if (props.isDragActive) {
      return '#2196f3';
    }
    return '#eeeeee';
  };

  const handleSave = async () => {
    try {
      setGlobalLoading(true);
      const result = await Promise.all(
        acceptedFiles.map(async (file) => {
          return new Promise((resolve, reject) => {
            let newImage = { url: file };
            let reader = new FileReader();

            reader.onload = function (e) {
              newImage.preview = e.target.result;
              resolve(newImage);
            };

            reader.readAsDataURL(file);
          });
        })
      );

      await mutate({
        mutation: CREATE_ANNOUNCEMENT,
        variables: {
          data: {
            title,
            description,
            document: result[0].url,
          },
        },
      });

      setSnack({
        variant: 'success',
        message: 'Pengumuman berhasil ditambah',
        opened: true,
      });

      setGlobalLoading(false);
      history.replace('/announcement');
    } catch (error) {
      setSnack({
        variant: 'error',
        message: 'Pengumuman gagal ditambah',
        opened: true,
      });

      setGlobalLoading(false);
    }
  };

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Pengumuman' }]} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="title"
                  name="title"
                  required
                  fullWidth
                  id="title"
                  label="Judul Pengumuman"
                  autoFocus
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  autoComplete="description"
                  name="description"
                  required
                  fullWidth
                  id="description"
                  label="Keterangan Pengumuman"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <h4>Unggah Berkas</h4>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    borderWidth: '2px',
                    borderRadius: '2px',
                    borderStyle: 'dashed',
                    borderColor: (props) => getColor(props),
                    backgroundColor: '#fafafa',
                    color: '#bdbdbd',
                    outline: 'none',
                    transition: 'border .24s ease-in-out',
                  }}
                  {...getRootProps({
                    className: 'dropzone',
                    isDragActive,
                    isDragAccept,
                    isDragReject,
                  })}
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here</p>
                </div>
                <aside>
                  <ul>{files}</ul>
                </aside>
              </Grid>
              <Button
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => handleSave()}
              >
                Simpan
              </Button>{' '}
              &nbsp;
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => history.replace('/announcement')}
              >
                Kembali
              </Button>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default NewsForm;
