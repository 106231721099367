import React, { useContext } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MonevCopyright from './MonevCopyright';
import { FORGOT_PASSWORD } from '../graphql/User';
import GlobalContext from '../utils/GlobalContext';

const MonevSignIn = (props) => {
  const { mutate, history } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <center>
          <img
            src="/logo.png"
            alt="logo"
            style={{
              width: '40%',
              marginBottom: '5px',
            }}
          />
          <img
            src="/logo-dishub.png"
            alt="logo"
            style={{
              width: '40%',
              marginBottom: '5px',
            }}
          />
          <Typography component="h1" variant="h6">
            Halaman Login <br /> Sistem Informasi Elektronik Keselamatan
            Angkutan Tulungagung
          </Typography>
        </center>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required('Harus diisi')
              .email('Format email tidak valid. Ex: example@email.com'),
          })}
          onSubmit={async ({ email }) => {
            try {
              setGlobalLoading(true);
              const {
                data: { forgotPassword },
              } = await mutate({
                mutation: FORGOT_PASSWORD,
                variables: { email },
                fetchPolicy: 'no-cache',
              });

              history.replace('/sign_in');
              setGlobalLoading(false);
              setSnack({
                variant: 'success',
                message: `Reset password berhasil, silahkan cek inbox / span email ${forgotPassword.email}`,
                opened: true,
              });
            } catch (error) {
              const message =
                error.message || 'Login gagal, silahkan coba lagi.';
              setSnack({
                variant: 'error',
                message,
                opened: true,
              });
              setGlobalLoading(false);
            }
          }}
          render={(props) => {
            let { handleChange, handleSubmit, values, errors, touched } = props;

            return (
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  autoComplete="email"
                  name="email"
                  required
                  margin="normal"
                  fullWidth
                  autoFocus
                  id="email"
                  label="Email"
                  placeholder="Ex: example@email.com"
                  onChange={handleChange}
                  value={values.email}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Request Password
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/sign_in" variant="body2">
                      Sudah memiliki akun? Login
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            );
          }}
        />
      </Box>
      <MonevCopyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default MonevSignIn;
