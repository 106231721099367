/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useContext } from 'react';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
  CssBaseline,
  TextField,
  FormHelperText,
  Grid,
  Box,
  Container,
  OutlinedInput,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import MonevBreadcrumbs from '../MonevBreadcumbs';
import { SERVICE_TYPES } from '../../graphql/ServiceTypes';
import { SUB_DISTRICTS } from '../../graphql/SubDistricts';
import { FIND_URBAN_VILLAGE_BY_SUB_DISTRICT_ID } from '../../graphql/UrbanVillages';
import {
  REGISTRATION,
  FIND_COMPANY_BY_EMAIL,
  FIND_COMPANY_BY_CID,
  UPDATE_COMPANY,
} from '../../graphql/Companies';
import GlobalContext from '../../utils/GlobalContext';

const CompanyForm = (props) => {
  const { query, history, mutate, editable, match } = props;
  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [serviceType, setServiceType] = useState([]);
  const [subDistricts, setSubDistricts] = useState([]);
  const [selectedSubDistrict, setSelectedSubDistrict] = useState(null);
  const [urbanVillages, setUrbanVillages] = useState([]);
  const [company, setCompany] = useState({});
  const [button, setButton] = useState(false);

  useEffect(() => {
    const getServiceType = async () => {
      const {
        data: { serviceTypes },
      } = await query({
        query: SERVICE_TYPES,
        fetchPolicy: 'no-cache',
      });

      setServiceType(serviceTypes);
    };

    const getSubDistricts = async () => {
      const { data } = await query({
        query: SUB_DISTRICTS,
        fetchPolicy: 'no-cache',
      });

      setSubDistricts(data.subDistricts);
    };

    getServiceType();
    getSubDistricts();
  }, [query]);

  useEffect(() => {
    const getVillageBySubDistrict = async () => {
      const {
        data: { findUrbanVillageBySubDistrictId },
      } = await query({
        query: FIND_URBAN_VILLAGE_BY_SUB_DISTRICT_ID,
        variables: {
          sub_district_id: selectedSubDistrict,
        },
        fetchPolicy: 'no-cache',
      });

      setUrbanVillages(findUrbanVillageBySubDistrictId);
    };

    if (selectedSubDistrict) {
      getVillageBySubDistrict();
    }
  }, [query, selectedSubDistrict]);

  useEffect(() => {
    if (editable) {
      const getCompany = async () => {
        setGlobalLoading(true);
        const {
          data: { findCompanyByCid },
        } = await query({
          query: FIND_COMPANY_BY_CID,
          variables: {
            cid: props.match.params.cid,
          },
          fetchPolicy: 'no-cache',
        });

        const {
          data: { findUrbanVillageBySubDistrictId },
        } = await query({
          query: FIND_URBAN_VILLAGE_BY_SUB_DISTRICT_ID,
          variables: {
            sub_district_id: findCompanyByCid?.urban_village?.sub_district.id,
          },
          fetchPolicy: 'no-cache',
        });

        setCompany(findCompanyByCid);
        setUrbanVillages(findUrbanVillageBySubDistrictId);
        setGlobalLoading(false);
      };

      getCompany();
    }
  }, [editable, props.match.params.cid, query, setGlobalLoading]);

  const MaskInput = (props) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        mask={[
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          '-',
          /\d/,
          /\d/,
          /\d/,
          '.',
          /\d/,
          /\d/,
          /\d/,
        ]}
        placeholderChar={'\u2000'}
      />
    );
  };

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Tambah Perusahaan' }]} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Formik
            initialValues={{
              companyName: company?.name || '',
              serviceType: company?.service_type_id || '',
              subDistrict: company?.urban_village?.sub_district?.id || '',
              urbanVillage: company?.urban_village?.id || '',
              npwp: company?.npwp || '',
              email: company?.email || '',
              phone: company?.phone || '',
              responsiblePhone: company?.responsible_phone || '',
              address: company?.address || '',
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              companyName: Yup.string().required('Harus diisi'),
              serviceType: Yup.string().required('Harus diisi'),
              subDistrict: Yup.string().required('Harus diisi'),
              urbanVillage: Yup.string().required('Harus diisi'),
              npwp: Yup.string().required('Harus diisi'),
              email: Yup.string()
                .required('Harus diisi')
                .email('Format email tidak valid. Ex: example@email.com'),
              phone: Yup.string()
                .required('Harus diisi')
                .matches(
                  /^([+][0-9]{1,4})*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{1,16}$/,
                  'Format telepon tidak valid. Ex: 08123456789, +628123456789, atau 628123456789'
                ),
              responsiblePhone: Yup.string()
                .required('Harus diisi')
                .matches(
                  /^([+][0-9]{1,4})*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{1,16}$/,
                  'Format telepon tidak valid. Ex: 08123456789, +628123456789, atau 628123456789'
                ),
              address: Yup.string().required('Harus diisi'),
            })}
            onSubmit={async ({
              companyName,
              serviceType,
              npwp,
              email,
              phone,
              responsiblePhone,
              address,
              urbanVillage,
            }) => {
              try {
                setGlobalLoading(true);
                setButton(true);

                const data = {
                  urban_village_id: urbanVillage,
                  service_type_id: serviceType,
                  name: companyName.trim(),
                  npwp: npwp.trim(),
                  email: email.trim().toLocaleLowerCase(),
                  phone: phone.trim(),
                  responsible_phone: responsiblePhone.trim(),
                  address: address.trim(),
                };
                let result;
                let message;

                // CHECK EXISTING EMAIL
                const {
                  data: { findCompanyByEmail },
                } = await mutate({
                  mutation: FIND_COMPANY_BY_EMAIL,
                  variables: {
                    email: data.email,
                  },
                });

                if (!editable && findCompanyByEmail) {
                  setSnack({
                    variant: 'error',
                    message: 'Email telah terdaftar dalam sistem.',
                    opened: true,
                  });
                  setGlobalLoading(false);
                  setButton(false);

                  return false;
                }

                if (editable) {
                  const {
                    data: { updateCompany },
                  } = await mutate({
                    mutation: UPDATE_COMPANY,
                    variables: {
                      cid: match.params.cid,
                      data,
                    },
                  });

                  result = updateCompany;
                  message = `Perusahaan ${result.name} berhasil diubah`;
                } else {
                  const {
                    data: { registration },
                  } = await mutate({
                    mutation: REGISTRATION,
                    variables: {
                      data,
                    },
                  });

                  result = registration;
                  message = `Perusahaan ${result.name} berhasil didaftarkan`;
                }

                setSnack({
                  variant: 'success',
                  message,
                  opened: true,
                });
                setGlobalLoading(false);
                setButton(false);

                history.replace('/company');
              } catch (error) {
                console.log('error', error);
                setSnack({
                  variant: 'error',
                  message: 'Registrasi gagal, silahkan coba lagi.',
                  opened: true,
                });
                setGlobalLoading(false);
                setButton(false);
              }
            }}
            render={(props) => {
              let {
                handleSubmit,
                handleChange,
                values,
                errors,
                touched,
                setFieldValue,
              } = props;
              return (
                <Box component="form" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <TextField
                        autoComplete="companyName"
                        name="companyName"
                        required
                        fullWidth
                        id="companyName"
                        label="Nama Perusahaan"
                        autoFocus
                        onChange={handleChange}
                        value={values.companyName}
                        error={errors.companyName && touched.companyName}
                        helperText={
                          errors.companyName &&
                          touched.companyName &&
                          errors.companyName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormControl fullWidth>
                        <InputLabel id="serviceType">Jenis Layanan</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="serviceType"
                          label="Jenis Layanan"
                          name="serviceType"
                          onChange={handleChange}
                          value={values.serviceType}
                          error={errors.serviceType && touched.serviceType}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {serviceType.length &&
                            serviceType.map((x) => (
                              <MenuItem value={x.id}>{x.name}</MenuItem>
                            ))}
                        </Select>
                        {errors.serviceType && touched.serviceType && (
                          <FormHelperText error>
                            {errors.serviceType}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel id="subDistrict">Kecamatan</InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="subDistrict"
                          label="Kecamatan"
                          name="subDistrict"
                          onChange={(e) => {
                            setFieldValue('subDistrict', e.target.value);
                            setFieldValue('urbanVillage', '');
                            setSelectedSubDistrict(e.target.value);
                          }}
                          value={values.subDistrict}
                          error={errors.subDistrict && touched.subDistrict}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {subDistricts.length &&
                            subDistricts.map((x) => (
                              <MenuItem value={x.id}>{x.name}</MenuItem>
                            ))}
                        </Select>
                        {errors.subDistrict && touched.subDistrict && (
                          <FormHelperText error>
                            {errors.subDistrict}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel id="urbanVillage">
                          Kelurahan / Desa
                        </InputLabel>
                        <Select
                          margin="normal"
                          fullWidth
                          id="urbanVillage"
                          label="Kelurahan / Desa"
                          name="urbanVillage"
                          onChange={handleChange}
                          value={values.urbanVillage}
                          error={errors.urbanVillage && touched.urbanVillage}
                        >
                          <MenuItem value="" disabled>
                            --- Pilih ---
                          </MenuItem>
                          {urbanVillages.length &&
                            urbanVillages.map((x) => (
                              <MenuItem value={x.id}>{x.name}</MenuItem>
                            ))}
                        </Select>
                        {errors.urbanVillage && touched.urbanVillage && (
                          <FormHelperText error>
                            {errors.urbanVillage}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <FormControl fullWidth>
                        <InputLabel shrink={editable} color="primary">
                          NPWP Perusahaan
                        </InputLabel>
                        <OutlinedInput
                          label="NPWP Perusahaan"
                          autoComplete="npwp"
                          name="npwp"
                          required
                          fullWidth
                          id="npwp"
                          placeholder="00.000.000.0-000.000"
                          inputComponent={MaskInput}
                          onChange={handleChange}
                          value={values.npwp}
                          error={errors.npwp && touched.npwp}
                        />
                        {errors.npwp && touched.npwp && (
                          <FormHelperText error>{errors.npwp}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        autoComplete="email"
                        name="email"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        placeholder="Ex: example@email.com"
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        autoComplete="phone"
                        name="phone"
                        required
                        fullWidth
                        id="phone"
                        label="Telepon"
                        placeholder="Ex: 08123456789"
                        onChange={handleChange}
                        value={values.phone}
                        error={errors.phone && touched.phone}
                        helperText={
                          errors.phone && touched.phone && errors.phone
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        autoComplete="responsiblePhone"
                        name="responsiblePhone"
                        required
                        fullWidth
                        id="responsiblePhone"
                        placeholder="Ex: 08123456789"
                        label="Telepon Penanggung Jawab"
                        onChange={handleChange}
                        value={values.responsiblePhone}
                        error={
                          errors.responsiblePhone && touched.responsiblePhone
                        }
                        helperText={
                          errors.responsiblePhone &&
                          touched.responsiblePhone &&
                          errors.responsiblePhone
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        autoComplete="address"
                        name="address"
                        required
                        fullWidth
                        multiline
                        rows={3}
                        id="address"
                        label="Alamat Perusahaan"
                        helperText="*) Kolom alamat diisi sesuai dengan domisili perusahaan yang dimiliki"
                        value={values.address}
                        error={errors.address && touched.address}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit}
                    disabled={button}
                  >
                    Simpan
                  </Button>{' '}
                  &nbsp;
                  <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={() => history.replace('/company')}
                  >
                    Kembali
                  </Button>
                </Box>
              );
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default CompanyForm;
