import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import { Divider } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import dayjs from 'dayjs';
import { Formik } from 'formik';
import * as Yup from 'yup';

import MonevBreadcrumbs from '../MonevBreadcumbs';
import GlobalContext from '../../utils/GlobalContext';
import { FIND_FLEET_BY_COMPANY_ID } from '../../graphql/Fleets';
import { CREATE_SPARE_PART } from '../../graphql/SpareParts';

const SparePartForm = (props) => {
  const { query, mutate, history } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [companyFleets, setCompanyFleets] = useState([]);
  const [form, setForm] = useState({
    fleet_id: {
      value: '',
      error: false,
    },
    oil: {
      brand: '',
      date: '',
      km: '',
      status: false,
    },
    oil_filter: {
      brand: '',
      date: '',
      km: '',
      status: false,
    },
  });
  const [extras, setExtras] = useState([
    {
      id: 1,
      name: '',
      date: '',
    },
  ]);
  const [tires, setTires] = useState([
    {
      id: 1,
      name: '',
      date: '',
      km: '',
    },
  ]);
  const [statusOil, setStatusOil] = useState(false);
  const [statusOilFilter, setStatusOilFilter] = useState(false);
  const [statusTire, setStatusTire] = useState(false);
  const [statusExtra, setStatusExtra] = useState(false);
  const [validateTireBrand, setValidateTireBrand] = useState({
    required: true,
    validate: false,
  });
  const [validateTireDate, setValidateTireDate] = useState({
    required: true,
    validate: false,
  });
  const [validateTireKm, setValidateTireKm] = useState({
    required: true,
    validate: false,
  });
  const [validateExtraName, setValidateExtraName] = useState({
    required: true,
    validate: false,
  });
  const [validateExtraDate, setValidateExtraDate] = useState({
    required: true,
    validate: false,
  });
  const currentDate = dayjs().format('YYYY-MM-DD');
  const { setGlobalLoading, setSnack } = useContext(GlobalContext);
  useEffect(() => {
    const getFleets = async () => {
      setGlobalLoading(true);
      const {
        data: { findFleetByCompanyId },
      } = await query({
        query: FIND_FLEET_BY_COMPANY_ID,
        fetchPolicy: 'no-cache',
      });

      setCompanyFleets(findFleetByCompanyId);
      setGlobalLoading(false);
    };

    getFleets();
  }, [query, setGlobalLoading]);

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // if (activeStep === 0) {
    if (!form.fleet_id.value) {
      setForm({ ...form, fleet_id: { ...form.fleet_id, error: true } });
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAddForm = (type) => {
    if (type === 'extras') {
      const oldExtras = extras;
      setExtras([
        ...oldExtras,
        { id: oldExtras.length + 1, name: '', date: '' },
      ]);
    } else if (type === 'tires') {
      const oldTires = tires;
      setTires([...oldTires, { id: oldTires.length + 1, name: '', date: '' }]);
    }
  };

  const handleRemoveForm = (type, i) => {
    if (type === 'extras') {
      let values = extras;

      values.splice(i, 1);
      setExtras([...values]);
    } else if (type === 'tires') {
      let values = tires;

      values.splice(i, 1);
      setTires([...values]);
    }
  };

  const formExtras = () => {
    return extras.map((el, i) => {
      return (
        <>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <TextField
              autoComplete={`extra_name_${i}`}
              name={`extra_name_${i}`}
              fullWidth
              id={`extra_name_${i}`}
              label="Nama Suku Cadang"
              placeholder="Busi / Kabel dll"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={statusExtra}
              onChange={(e) => {
                let newArr = [...extras];
                newArr[i] = { ...newArr[i], name: e.target.value };

                setExtras([...newArr]);
                setValidateExtraName({ required: true, validate: false });
              }}
              value={el.name}
              error={validateExtraName.required && validateExtraName.validate}
              helperText={
                validateExtraName.required &&
                validateExtraName.validate &&
                'Harus Diisi'
              }
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <TextField
              id={`extra_date_${i}`}
              fullWidth
              label="Tanggal Penggantian"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: currentDate,
              }}
              style={{ width: '100%' }}
              disabled={statusExtra}
              onChange={(e) => {
                let newArr = [...extras];
                newArr[i] = { ...newArr[i], date: e.target.value };

                setExtras([...newArr]);
                setValidateExtraDate({ required: true, validate: false });
              }}
              value={el.date}
              error={validateExtraDate.required && validateExtraDate.validate}
              helperText={
                validateExtraDate.required &&
                validateExtraDate.validate &&
                'Harus Diisi'
              }
            />
          </Grid>
          {i === 0 && (
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Button
                variant="outlined"
                onClick={() => handleAddForm('extras')}
                sx={{ mt: 2, mr: 1 }}
                disabled={statusExtra}
              >
                Tambah
              </Button>
            </Grid>
          )}
          {i > 0 && (
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <Button
                variant="text"
                onClick={() => handleRemoveForm('extras', i)}
                sx={{ mt: 2, mr: 1 }}
                disabled={statusExtra}
              >
                Hapus
              </Button>
            </Grid>
          )}
        </>
      );
    });
  };

  const formTires = () => {
    return tires.map((el, i) => {
      return (
        <>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextField
              autoComplete={`tire_name_${i}`}
              name={`tire_name_${i}`}
              fullWidth
              id={`tire_name_${i}`}
              label="Merk Ban"
              placeholder="Dynapro HT RH12 225/65 R17"
              disabled={statusTire}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                let newArr = [...tires];
                newArr[i] = { ...newArr[i], name: e.target.value };

                setTires([...newArr]);
                setValidateTireBrand({ required: true, validate: false });
              }}
              value={el.name}
              error={validateTireBrand.required && validateTireBrand.validate}
              helperText={
                validateTireBrand.required &&
                validateTireBrand.validate &&
                'Harus Diisi'
              }
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextField
              id={`tire_date_${i}`}
              fullWidth
              label="Tanggal Penggantian"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: currentDate,
              }}
              style={{ width: '100%' }}
              disabled={statusTire}
              onChange={(e) => {
                let newArr = [...tires];
                newArr[i] = { ...newArr[i], date: e.target.value };

                setTires([...newArr]);
                setValidateTireDate({ required: true, validate: false });
              }}
              value={el.date}
              error={validateTireDate.required && validateTireDate.validate}
              helperText={
                validateTireDate.required &&
                validateTireDate.validate &&
                'Harus Diisi'
              }
            />
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <TextField
              autoComplete={`tire_km_${i}`}
              name={`tire_km_${i}`}
              fullWidth
              id={`tire_km_${i}`}
              label="Kilometer"
              placeholder="10.000"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              disabled={statusTire}
              onChange={(e) => {
                let newArr = [...tires];
                newArr[i] = { ...newArr[i], km: e.target.value };

                setTires([...newArr]);
                setValidateTireKm({ required: true, validate: false });
              }}
              value={el.km}
              error={validateTireKm.required && validateTireKm.validate}
              helperText={
                validateTireKm.required &&
                validateTireKm.validate &&
                'Harus Diisi'
              }
            />
          </Grid>
          {i === 0 && (
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Button
                variant="outlined"
                onClick={() => handleAddForm('tires')}
                disabled={statusTire}
                sx={{ mt: 2, mr: 1 }}
              >
                Tambah
              </Button>
            </Grid>
          )}
          {i > 0 && (
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <Button
                variant="text"
                onClick={() => handleRemoveForm('tires', i)}
                disabled={statusTire}
                sx={{ mt: 2, mr: 1 }}
              >
                Hapus
              </Button>
            </Grid>
          )}
        </>
      );
    });
  };

  const handleSave = async () => {
    setGlobalLoading(true);

    try {
      form.oil.status = statusOil;
      form.oil_filter.status = statusOilFilter;
      const part = form;
      const extra = {
        skipped: statusExtra,
        extras,
      };
      const tire = {
        skipped: statusTire,
        tires,
      };
      part.extra = extra;
      part.tire = tire;

      const data = {
        fleet_id: form.fleet_id.value,
        part,
      };

      await mutate({
        mutation: CREATE_SPARE_PART,
        variables: { data },
      });

      setSnack({
        variant: 'success',
        message: 'Data suku cadang berhasil ditambahkan',
        opened: true,
      });

      setGlobalLoading(false);
      history.replace('/spare_part');
    } catch (error) {
      console.log('error', error);
      setSnack({
        variant: 'error',
        message: 'Tambah suku cadang gagal, silahkan coba lagi.',
        opened: true,
      });
      setGlobalLoading(false);
    }
  };

  const steps = [
    {
      label: 'Merk Armada',
      description: (
        <>
          <FormControl fullWidth>
            <InputLabel id="fleet_id">Jenis Armada</InputLabel>
            <Select
              margin="normal"
              fullWidth
              id="fleet_id"
              label="Jenis Armada"
              name="fleet_id"
              onChange={(e) =>
                setForm({
                  ...form,
                  fleet_id: { value: e.target.value, error: false },
                })
              }
              value={form.fleet_id?.value}
              error={form?.fleet_id?.error}
            >
              <MenuItem value="" disabled>
                --- Pilih ---
              </MenuItem>

              {companyFleets.fleets?.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.brand} {x.type}
                </MenuItem>
              ))}
            </Select>
            {form?.fleet_id?.error && (
              <FormHelperText error>Harus diisi.</FormHelperText>
            )}
          </FormControl>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 2, mr: 1 }}
          >
            Lanjut
          </Button>
          <Button disabled onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
            Kembali
          </Button>
        </>
      ),
    },
    {
      label: 'Penggantian Oli Mesin dan Filter Oli',
      description: (
        <>
          <Formik
            initialValues={{
              oil_brand: form?.oil?.brand,
              oil_date: form?.oil?.date,
              oil_km: form?.oil?.km,
              oil_status: statusOil,
              oil_filter_brand: form?.oil_filter?.brand,
              oil_filter_date: form?.oil_filter?.date,
              oil_filter_km: form?.oil_filter?.km,
              oil_filter_status: statusOilFilter,
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              oil_brand: Yup.string().when('oil_status', {
                is: false,
                then: Yup.string().required('Harus diisi'),
              }),
              oil_date: Yup.string().when('oil_status', {
                is: false,
                then: Yup.string().required('Harus diisi'),
              }),
              oil_km: Yup.string().when('oil_status', {
                is: false,
                then: Yup.string().required('Harus diisi'),
              }),
              oil_filter_brand: Yup.string().when('oil_filter_status', {
                is: false,
                then: Yup.string().required('Harus diisi'),
              }),
              oil_filter_date: Yup.string().when('oil_filter_status', {
                is: false,
                then: Yup.string().required('Harus diisi'),
              }),
              oil_filter_km: Yup.string().when('oil_filter_status', {
                is: false,
                then: Yup.string().required('Harus diisi'),
              }),
            })}
            render={(props) => {
              let { values, errors, touched, validateForm, setTouched } = props;

              return (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        autoComplete="oil_brand"
                        name="oil_brand"
                        fullWidth
                        id="oil_brand"
                        label="Merek Oli Mesin"
                        placeholder="Shell Helix"
                        disabled={statusOil}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            oil: { ...form.oil, brand: e.target.value },
                          });
                        }}
                        value={values.oil_brand}
                        error={errors.oil_brand && touched.oil_brand}
                        helperText={
                          errors.oil_brand &&
                          touched.oil_brand &&
                          errors.oil_brand
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="oil_date"
                        name="oil_date"
                        fullWidth
                        label="Tanggal Penggantian"
                        type="date"
                        sx={{ width: 220 }}
                        disabled={statusOil}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: currentDate,
                        }}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            oil: { ...form.oil, date: e.target.value },
                          });
                        }}
                        value={values.oil_date}
                        error={errors.oil_date && touched.oil_date}
                        helperText={
                          errors.oil_date && touched.oil_date && errors.oil_date
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <TextField
                        autoComplete="oil_km"
                        name="oil_km"
                        fullWidth
                        id="oil_km"
                        label="Kilometer"
                        placeholder="10000"
                        type="number"
                        disabled={statusOil}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            oil: { ...form.oil, km: e.target.value },
                          });
                        }}
                        value={values.oil_km}
                        error={errors.oil_km && touched.oil_km}
                        helperText={
                          errors.oil_km && touched.oil_km && errors.oil_km
                        }
                      />
                    </Grid>
                    <Divider />
                    <Grid item xs={6} sm={6} md={1} lg={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            value={statusOil}
                            onClick={() => setStatusOil(!statusOil)}
                          />
                        }
                        label="Tidak ada penggantian"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        autoComplete="oil_filter_brand"
                        name="oil_filter_brand"
                        fullWidth
                        id="oil_filter_brand"
                        label="Merek Filter Oli"
                        placeholder="Magnetto"
                        disabled={statusOilFilter}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            oil_filter: {
                              ...form.oil_filter,
                              brand: e.target.value,
                            },
                          });
                        }}
                        value={values.oil_filter_brand}
                        error={
                          errors.oil_filter_brand && touched.oil_filter_brand
                        }
                        helperText={
                          errors.oil_filter_brand &&
                          touched.oil_filter_brand &&
                          errors.oil_filter_brand
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <TextField
                        id="oil_filter_date"
                        name="oil_filter_date"
                        fullWidth
                        label="Tanggal Penggantian"
                        type="date"
                        sx={{ width: 220 }}
                        disabled={statusOilFilter}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          max: currentDate,
                        }}
                        style={{ width: '100%' }}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            oil_filter: {
                              ...form.oil_filter,
                              date: e.target.value,
                            },
                          });
                        }}
                        value={values.oil_filter_date}
                        error={
                          errors.oil_filter_date && touched.oil_filter_date
                        }
                        helperText={
                          errors.oil_filter_date &&
                          touched.oil_filter_date &&
                          errors.oil_filter_date
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                      <TextField
                        autoComplete="oil_filter_km"
                        name="oil_filter_km"
                        fullWidth
                        id="oil_filter_km"
                        label="Kilometer"
                        placeholder="10000"
                        type="number"
                        disabled={statusOilFilter}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            oil_filter: {
                              ...form.oil_filter,
                              km: e.target.value,
                            },
                          });
                        }}
                        value={values.oil_filter_km}
                        error={errors.oil_filter_km && touched.oil_filter_km}
                        helperText={
                          errors.oil_filter_km &&
                          touched.oil_filter_km &&
                          errors.oil_filter_km
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={1} lg={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            value={statusOilFilter}
                            onClick={() => setStatusOilFilter(!statusOilFilter)}
                          />
                        }
                        label="Tidak ada penggantian"
                      />
                    </Grid>
                  </Grid>
                  <Button
                    variant="contained"
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (
                          Object.entries(errors).length === 0 &&
                          errors.constructor === Object
                        ) {
                          handleNext();
                        } else {
                          setTouched(errors);
                        }
                      });
                    }}
                    sx={{ mt: 2, mr: 1 }}
                  >
                    Lanjut
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
                    Kembali
                  </Button>
                </>
              );
            }}
          />
        </>
      ),
    },
    {
      label: 'Penggantian Ban Mobil',
      description: (
        <>
          <Grid container spacing={2}>
            {formTires()}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    value={statusTire}
                    onClick={() => {
                      setStatusTire(!statusTire);
                      setValidateTireBrand({ required: true, validate: false });
                      setValidateTireDate({ required: true, validate: false });
                      setValidateTireKm({ required: true, validate: false });
                    }}
                  />
                }
                label="Tidak ada penggantian"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={() => {
              if (!statusTire) {
                const blankBrand = tires.filter((x) => x.name);
                const blankDate = tires.filter((x) => x.date);
                const blankKm = tires.filter((x) => x.km);

                if (blankBrand && !blankBrand.length) {
                  setValidateTireBrand({ required: true, validate: true });
                }

                if (blankDate && !blankDate.length) {
                  setValidateTireDate({ required: true, validate: true });
                }

                if (blankKm && !blankKm.length) {
                  setValidateTireKm({ required: true, validate: true });
                }

                if (
                  !blankDate.length &&
                  !blankBrand.length &&
                  !blankKm.length
                ) {
                  return;
                }
              }

              handleNext();
            }}
            sx={{ mt: 2, mr: 1 }}
          >
            Lanjut
          </Button>
          <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
            Kembali
          </Button>
        </>
      ),
    },
    {
      label: 'Suku Cadang Lainnya',
      description: (
        <>
          <Grid container spacing={2}>
            {formExtras()}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    value={statusExtra}
                    onClick={() => {
                      setStatusExtra(!statusExtra);
                      setValidateExtraName({ required: true, validate: false });
                      setValidateExtraDate({ required: true, validate: false });
                    }}
                  />
                }
                label="Tidak ada penggantian"
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            onClick={() => {
              if (!statusExtra) {
                const blankBrand = extras.filter((x) => x.name);
                const blankDate = extras.filter((x) => x.date);

                if (blankBrand && !blankBrand.length) {
                  setValidateExtraName({ required: true, validate: true });
                }

                if (blankDate && !blankDate.length) {
                  setValidateExtraDate({ required: true, validate: true });
                }

                if (!blankDate.length && !blankBrand.length) {
                  return;
                }
              }

              handleSave();
            }}
            sx={{ mt: 2, mr: 1 }}
            disabled={statusOil && statusOilFilter && statusTire && statusExtra}
          >
            Simpan
          </Button>
          <Button onClick={handleBack} sx={{ mt: 2, mr: 1 }}>
            Kembali
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <MonevBreadcrumbs item={[{ title: 'Tambah Suku Cadang' }]} />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  optional={
                    <Typography variant="caption">
                      {index === 0 && 'Pilih armada\n\n'}
                      {index === 1 &&
                        'Diisi jika dilakukan penggantian oli atau filter oli sebelum dilakukan uji KIR'}
                      {index === 2 &&
                        'Diisi jika dilakukan penggantian ban sebelum dilakukan uji KIR'}
                    </Typography>
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  <Typography>{step.description}</Typography>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              <Typography>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                Reset
              </Button>
            </Paper>
          )}
        </Box>
      </Container>
    </>
  );
};

export default SparePartForm;
