import React, { useEffect, useContext, useState } from 'react';
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GlobalContext from '../utils/GlobalContext';
import { FIND_USER_BY_TOKEN, VERIFY_USER } from '../graphql/User';

const MonevVerification = (props) => {
  const { match, mutate, query, history } = props;
  const token = match.params.token;

  const { setSnack, setGlobalLoading } = useContext(GlobalContext);
  const [user, setUser] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      setGlobalLoading(true);
      const {
        data: { findUserByToken },
      } = await query({
        query: FIND_USER_BY_TOKEN,
        variables: {
          token,
        },
        fetchPolicy: 'no-cache',
      });

      // IF TOKEN NOT FOUND
      if (!findUserByToken) {
        setDisabled(true);
        setSnack({
          variant: 'error',
          message: 'Token tidak valid',
          opened: true,
        });
      }

      setUser(findUserByToken);
      setGlobalLoading(false);
    };

    getUser();
  }, [query, setGlobalLoading, setSnack, token]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 4,
          display: 'block',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <center>
          <img
            src="/logo.png"
            alt="logo"
            style={{
              width: '20%',
              marginBottom: '5px',
            }}
          />
          <Typography component="h1" variant="h6">
            Halaman Login <br /> Sistem Informasi Elektronik Keselamatan
            Angkutan Tulungagung <br />
            SI - Lek Slamet
          </Typography>
        </center>
        <Formik
          initialValues={{
            email: user?.email || '',
            password: '',
            showPassword: false,
            passwordConfirmation: '',
            showPasswordConfirmation: false,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            password: Yup.string().required('Harus diisi'),
            passwordConfirmation: Yup.string()
              .required('Harus diisi')
              .oneOf([Yup.ref('password'), null], 'Password tidak sama'),
          })}
          onSubmit={async ({ password, passwordConfirmation }) => {
            try {
              setGlobalLoading(true);
              setDisabled(true);

              const {
                data: { verifyUser },
              } = await mutate({
                mutation: VERIFY_USER,
                variables: {
                  token,
                  password,
                  password_confirmation: passwordConfirmation,
                },
              });

              setSnack({
                variant: 'success',
                message: `Email ${verifyUser.email} berhasil diverifikasi`,
                opened: true,
              });

              setGlobalLoading(false);
              setDisabled(false);

              history.replace('/');
            } catch (error) {
              const message =
                error.message || 'Registrasi gagal, silahkan coba lagi.';
              setSnack({
                variant: 'error',
                message,
                opened: true,
              });
              setGlobalLoading(false);
              setDisabled(false);
            }
          }}
          render={(props) => {
            let {
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              setFieldValue,
            } = props;
            return (
              <Box component="form" noValidate sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <TextField
                      autoComplete="email"
                      name="email"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      placeholder="Ex: example@email.com"
                      onChange={handleChange}
                      value={values.email}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} />

                  {/* PASSWORD SECTION */}
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password *
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        name="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange}
                        fullWidth
                        autoFocus
                        error={errors.password && touched.password}
                        disabled={disabled}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFieldValue(
                                  'showPassword',
                                  !values.showPassword
                                )
                              }
                              edge="end"
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      {errors.password && touched.password && (
                        <FormHelperText error>{errors.password}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} />

                  {/* PASSWORD CONFIRMATION SECTION */}
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Konfirmasi Password *
                      </InputLabel>
                      <OutlinedInput
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        type={
                          values.showPasswordConfirmation ? 'text' : 'password'
                        }
                        value={values.passwordConfirmation}
                        onChange={handleChange}
                        fullWidth
                        error={
                          errors.passwordConfirmation &&
                          touched.passwordConfirmation
                        }
                        disabled={disabled}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setFieldValue(
                                  'showPasswordConfirmation',
                                  !values.showPasswordConfirmation
                                )
                              }
                              edge="end"
                            >
                              {values.showPasswordConfirmation ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                      {errors.passwordConfirmation &&
                        touched.passwordConfirmation && (
                          <FormHelperText error>
                            {errors.passwordConfirmation}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} />

                  {/* BUTTON SECTION */}
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleSubmit}
                      disabled={disabled}
                    >
                      Simpan
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} />
                </Grid>
              </Box>
            );
          }}
        />
      </Box>
    </Container>
  );
};

export default MonevVerification;
