/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  Toolbar,
  Tooltip,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DeleteIcon from '@mui/icons-material/Delete';

const MonevTable = (props) => {
  const {
    title,
    history,
    header,
    data,
    editable,
    editUrl,
    editKey,
    disableSearch,
    handleUpdate,
    noAdd,
    totalData,
    refetch,
    offset,
    setOffset,
    paginate,
    handleClickOpen,
  } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState(data);
  const [searched, setSearched] = useState('');

  useEffect(() => {
    setRows(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleChangePage = async (event, newPage) => {
    const a = rows.slice(
      newPage * rowsPerPage,
      newPage * rowsPerPage + rowsPerPage
    );

    if (paginate && !a.length) {
      setOffset(offset + 1);
      await refetch(offset);
      setPage(newPage);
    } else {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    let filteredRows;
    // if (editUrl === 'fleet' || editUrl === 'complaints') {
    filteredRows = data.filter((row) => {
      let search = searchedVal.toLowerCase();
      var values = Object.values(row);
      var flag = false;

      values.forEach((val) => {
        if (val && typeof val === 'object') {
          if (val?.name?.toLowerCase().indexOf(search) > -1) {
            flag = true;
            return;
          }
        } else if (val && typeof val === 'string') {
          if (val?.toLowerCase().indexOf(search) > -1) {
            flag = true;
            return;
          }
        }
      });

      if (flag) return row;
    });

    setRows(filteredRows);
    setSearched(searchedVal);
  };

  const clearSearch = () => {
    setSearched('');
    setRows(data);
  };

  const ToolbarTitle = (props) => {
    const { numSelected, filter } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        <React.Fragment>
          {!disableSearch && (
            <FormControl size="small" margin="dense">
              <OutlinedInput
                name="search"
                margin="dense"
                autoFocus
                id="search"
                placeholder="Cari"
                onChange={(e) => requestSearch(e.target.value)}
                value={searched}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => clearSearch()}>
                      {searched ? <ClearIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
        </React.Fragment>
        <div style={{ position: 'absolute', right: 0, marginRight: 25 }}>
          {!noAdd && (
            <Tooltip title="Tambah Data">
              <IconButton
                onClick={() => history.push(`/${editUrl}/add`)}
                style={{ backgroundColor: '#2F86A6', color: 'white' }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          {filter && (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </Toolbar>
    );
  };

  const rowsPerPageOptions = paginate ? [10] : [10, 25, 100];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <ToolbarTitle
        title={title}
        filter={false}
        disableSearch={disableSearch}
      />
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {header.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              {editable && (
                <TableCell
                  key="editable"
                  align="center"
                  style={{ width: 125, textAlign: 'center' }}
                >
                  Aksi
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                    {header.map((column) => {
                      let value;
                      if (editUrl === 'driver' && column.id === 'role.code') {
                        value = row.role?.code;
                      } else if (
                        editUrl === 'fleet' &&
                        column.id === 'vehicle_purpose.name'
                      ) {
                        value = row.vehicle_purpose?.name;
                      } else if (
                        editUrl === 'complaints' &&
                        column.id === 'complaint_type.name'
                      ) {
                        value = row.complaint_type?.name;
                      } else {
                        value = row[column.id];
                      }

                      if (value === true) {
                        value = 'Aktif';
                      }

                      if (value === false) {
                        value = 'Inaktif';
                      }
                      return (
                        <>
                          {column.id !== 'action' &&
                            column.id !== 'edit_blog' &&
                            column.id !== 'edit_driver' && (
                              <TableCell key={idx} align={column.align}>
                                {value}
                              </TableCell>
                            )}
                          {column.id === 'action' && (
                            <TableCell key={idx} align={column.align}>
                              {row?.active === true && (
                                <Tooltip title="Inaktifkan">
                                  <IconButton
                                    id={idx}
                                    onClick={() =>
                                      handleUpdate(false, row?.crid || row?.aid)
                                    }
                                  >
                                    <ToggleOnIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {row?.active === false && (
                                <Tooltip title="Aktifkan">
                                  <IconButton
                                    id={idx}
                                    onClick={() =>
                                      handleUpdate(true, row?.crid || row?.aid)
                                    }
                                  >
                                    <ToggleOffIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {editKey === 'crid' && (
                                <Tooltip title="Hapus">
                                  <IconButton
                                    id={idx}
                                    onClick={() => handleClickOpen(row.crid)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          )}
                          {column.id === 'edit_blog' && (
                            <TableCell key={idx} align={column.align}>
                              <Tooltip title="Edit">
                                <IconButton
                                  id={idx}
                                  onClick={() =>
                                    history.push(
                                      `/${editUrl}/edit/${rows[idx][editKey]}`
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Hapus">
                                <IconButton
                                  id={idx}
                                  onClick={() => handleClickOpen(row.bid)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )}
                          {column.id === 'edit_driver' && (
                            <TableCell key={idx} align={column.align}>
                              <Tooltip title="Edit">
                                <IconButton
                                  id={idx}
                                  onClick={() =>
                                    history.push(
                                      `/${editUrl}/edit/${rows[idx][editKey]}`
                                    )
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Hapus">
                                <IconButton
                                  id={idx}
                                  onClick={() => handleClickOpen(row.cpid)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          )}
                        </>
                      );
                    })}
                    {editable && (
                      <TableCell key={idx} align="left">
                        <Tooltip title="Detail">
                          <IconButton
                            id={idx}
                            onClick={() =>
                              history.push(
                                `/${editUrl}/detail/${rows[idx][editKey]}`
                              )
                            }
                          >
                            <ListIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                          <IconButton
                            id={idx}
                            onClick={() =>
                              history.push(
                                `/${editUrl}/edit/${rows[idx][editKey]}`
                              )
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {!rows.length && 'Data tidak ditemukan'}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={paginate ? totalData : rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default MonevTable;
